import React from "react";
import Faq from "../images/faq.png";
import {
  FaqDiv,
  FaqImg,
  FaqSubTitle,
  FaqTitle,
  FooterMain,
  LearnLink,
} from "../styles";

function FAQ() {
  return (
    <FaqDiv>
      <FaqImg src={Faq} />
      <FooterMain style={{ textAlign: "left" }}>
        <FaqTitle>FAQs</FaqTitle>
        <FaqSubTitle>
          Please click on the learn more button to read all frequently asked
          questions about Staking at Demodyfi.
        </FaqSubTitle>{" "}
        <LearnLink
          href="https://docs.demodyfi.com/products/demody-staking"
          target="#"
        >
          Learn More <span> {">"} </span>
        </LearnLink>{" "}
      </FooterMain>
      {/* <Row>
        <VectorImg src={Vector} />
        <FooterMain style={{ textAlign: "left" }}>
          <FaqTitle>
            Checkout <span style={{ color: "#FF9A36" }}>FAQs</span> for more
            information!
          </FaqTitle>
          <UnionImg src={Union} />
          <FaqSubTitle>
            {"For any queries and doubts please visit our FAQs page "}
            <span></span>
          </FaqSubTitle>{" "}
          <a
            href="https://docs.demodyfi.com/products/demody-staking"
            target="#"
            style={{ color: "white" }}
          >
            <LearnImg src={Learn} />
          </a>{" "}
        </FooterMain>
      </Row>
      <Row></Row> */}
    </FaqDiv>
  );
}
export default FAQ;
