// This address points to a dummy ERC20 contract deployed on Ethereum Mainnet,
// Goerli, Kovan, Rinkeby and Ropsten. Replace it with your smart contracts.
const addresses = {
  ceaErc20: "0xc1C0472c0C80bCcDC7F5D01A376Bd97a734B8815",
  bnbt: {
    dmodToken: "0x2417c518951e6F080CD1b9A241E65a886EEc7Ebe",
    dmodTokenLP: "0x0772af93d31127f22e3d3314c54c64f717d5d945",
    uniswapRouter: "0x9Ac64Cc6e4415144C455BD8E4837Fea55603e5c3",
    WETHAddress: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
    USDTAddress: "0x78867bbeef44f2326bf8ddd1941a4439382ef2a7",
    communityVault: "0x1DD626DdBBe66b5933Cdd11014fba75b0E1b461f",
    staking: "0x8cC76622B7283Bb9421910E4D7A8cD340dbD9e2b",
    yieldFarmDmod: "0x8C1fa5B0423189945643782486e184DB35af6709",
    yieldFarmDmodLP: "0x8C1fa5B0423189945643782486e184DB35af6709",
  },
  rinkeby: {
    dmodToken: "0xD8d51b568533D1B6B3E1487b5D89610A9b0aad09",
    dmodTokenLP: "0xeeec24ca64df18109fef52a35ad84a4e22b39f26",
    uniswapRouter: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    WETHAddress: "0xc778417e063141139fce010982780140aa0cd5ab",
    USDTAddress: "0x5592EC0cfb4dbc12D3aB100b257153436a1f0FEa",
    communityVault: "0x3079DEa27adbdAA8C4b456027986a7f38f8CF893",
    staking: "0x0Ef0C460a32f02F79f5F6E6127b56Fc1057542DA",
    yieldFarmDmod: "0x1D27C22e7159e09B3f074686dffda8549AeAab99",
    yieldFarmDmodLP: "0x1D27C22e7159e09B3f074686dffda8549AeAab99",
  },
  bnb: {
    dmodToken: "0x002D8563759f5e1EAf8784181F3973288F6856e4",
    dmodTokenLP: "0x0f35d854C267D29C0E418F561b75aE09B9E413D4",
    uniswapRouter: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
    WETHAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    USDTAddress: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    communityVault: "0xC61E0BCd1e5dD1c08bf6d3DAEc672D4b09d54196",
    staking: "0xc94e085E2E2D92A950fa4A6B923263C0B47c6dBa",
    yieldFarmDmod: "0xf0915C64571d8efF349b3eBf4E548Ed51Da4C018",
    yieldFarmDmodLP: "0xB7D08e143B537bC5d9d78F80C511A46Cf607BC79",
  },
  homestead: {
    dmodToken: "0x5f6c5C2fB289dB2228d159C69621215e354218d7",
    dmodTokenLP: "0xD5B1Cd8D245A93E0697707AEe82497388508b132",
    uniswapRouter: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    WETHAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    USDTAddress: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
    communityVault: "0xDCa7e622E569C6C4CEC3bc354bf4a79220913109",
    staking: "0x024D59Ac0Bb03dEd28B9A16cd50B3d242B43a683",
    yieldFarmDmod: "0x564dE9dAC2Fb2666A9512d20cE2A3d65A0231A8e",
    yieldFarmDmodLP: "0xde3843D3d9009039790575F34A29acC6eC535642",
  },
};

export default addresses;
