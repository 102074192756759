import {
  faGithub,
  faLinkedin,
  faMedium,
  faTelegram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import React from "react";
import dmod from "../images/dmod2.png";
import {
  FooterDiv,
  FooterFontIcon,
  FooterIcon,
  FooterLogo,
  FooterRow,
  FooterText,
  FooterTextTwo,
} from "../styles";

function Footer() {
  return (
    <FooterDiv>
      {/* <AnalyticsModal isOpen={isOpen} onDismiss={() => setIsOpen(false)} /> */}
      <FooterRow style={{ padding: "1rem 0rem", flexGrow: 1 }}>
        <FooterLogo src={dmod} />
        <FooterText
          style={{ display: "flex", lineHeight: "16px", marginLeft: "0.5rem" }}
        >
          © 2022 All Rights Reserved
        </FooterText>{" "}
        <FooterTextTwo href="https://bit.ly/31mO29W" target="_blank">
          Terms
          <span style={{ color: " #302948", marginLeft: "0.5rem" }}>
            {" "}
            •{" "}
          </span>{" "}
        </FooterTextTwo>
        <FooterTextTwo
          href="https://drive.google.com/file/d/12Gkui0Q9ArmXde6T5MdxkuVTq2NWjQCF/view"
          target="_blank"
        >
          Privacy
          <span style={{ color: " #302948", marginLeft: "0.5rem" }}>
            {" "}
            •{" "}
          </span>{" "}
        </FooterTextTwo>
        <FooterTextTwo href="https://demodyfi.com" target="_blank">
          About
        </FooterTextTwo>
      </FooterRow>
      {/* <FooterFirstRow style={{ padding: "1rem 0rem", cursor: "pointer" }}>
        <Activity size="20" style={{ color: "#A6A0BB", height: "1rem" }} />
        <FooterText
          style={{
            display: "flex",
            lineHeight: "16px",
            marginRight: "0.5rem",
            marginLeft: "0.5rem",
          }}
        >
          Analytics
        </FooterText>
      </FooterFirstRow> */}

      <FooterRow style={{ padding: "0.6rem 0rem" }}>
        <FooterIcon
          style={{ marginLeft: "0.5rem" }}
          onClick={(e) =>
            window.open(
              "https://github.com/DemodyFI",
              "_blank",
              "noopener noreferrer"
            )
          }
        >
          <FooterFontIcon icon={faGithub} />
        </FooterIcon>
        <FooterIcon
          onClick={(e) =>
            window.open(
              "https://www.linkedin.com/company/demodyfi/",
              "_blank",
              "noopener noreferrer"
            )
          }
        >
          <FooterFontIcon icon={faLinkedin} />
        </FooterIcon>
        <FooterIcon
          onClick={(e) =>
            window.open(
              "https://twitter.com/DemodyFi",
              "_blank",
              "noopener noreferrer"
            )
          }
        >
          <FooterFontIcon icon={faTwitter} />
        </FooterIcon>
        <FooterIcon
          onClick={(e) =>
            window.open(
              "https://t.me/DemodyFI",
              "_blank",
              "noopener noreferrer"
            )
          }
        >
          <FooterFontIcon icon={faTelegram} />
        </FooterIcon>
        <FooterIcon
          onClick={(e) =>
            window.open(
              "https://demodyfi.medium.com/",
              "_blank",
              "noopener noreferrer"
            )
          }
        >
          <FooterFontIcon icon={faMedium} />
        </FooterIcon>
      </FooterRow>
    </FooterDiv>
  );
}
export default Footer;
