import React, { useEffect, useState } from "react";
import { Check, Copy } from "react-feather";
import Modal from "react-modal";
import {
  AccountControl,
  AccountGroupingRow,
  AccountSection,
  AddressLink,
  CloseIcon,
  InfoCard,
  LinkIcon,
  ModalContent,
  ModalHeader,
  TextModalSubHeading,
  UpperSection,
  WalletAction,
  YourAccount,
} from "../../styles";
import { formatAddress } from "../../utils";
import Identicon from "../Identicon";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "16px",
    border: "0px",
    padding: "0",
    overflow: "none",
    backgroundColor: "rgb(17, 16, 29)",
  },
};

function AccountInfoModal({ web3, modalIsOpen, closeModal }) {
  const [
    provider,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    signer,
    address,
    network,
    dmodBalance,
  ] = web3;
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  }, [copied]);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Pool Modal"
      shouldCloseOnOverlayClick={true}
    >
      <ModalContent>
        <UpperSection>
          <ModalHeader>
            <span>Account</span>
            <span>
              <CloseIcon onClick={closeModal} />
            </span>
          </ModalHeader>
          <AccountSection>
            <YourAccount>
              <InfoCard>
                <AccountGroupingRow>
                  <TextModalSubHeading>
                    Connected With MetaMask
                  </TextModalSubHeading>
                  <div>
                    <WalletAction
                      style={{ fontSize: ".825rem", fontWeight: 400 }}
                      onClick={() => {
                        logoutOfWeb3Modal();
                        closeModal();
                      }}
                    >
                      Disconnect
                    </WalletAction>
                  </div>
                </AccountGroupingRow>
                <AccountGroupingRow style={{ justifyContent: "flex-start" }}>
                  <>
                    <AccountControl style={{ justifyContent: "flex-start" }}>
                      <div
                        style={{ marginTop: "-0.8rem", marginRight: "0.5rem" }}
                      >
                        <Identicon address={"address"} />
                      </div>
                      <div>
                        <h4>{address ? formatAddress(address) : null}</h4>
                      </div>
                    </AccountControl>
                  </>
                </AccountGroupingRow>
                <AccountGroupingRow>
                  <AccountControl>
                    <AddressLink
                      onClick={() => {
                        setCopied(true);
                        window.navigator.clipboard.writeText(address);
                      }}
                    >
                      {copied ? <Check size={"16"} /> : <Copy size={"16"} />}
                      <span style={{ marginLeft: "4px" }}>Copy Address</span>
                    </AddressLink>
                    <AddressLink
                      target="_blank"
                      href={
                        network === "homestead"
                          ? "https://etherscan.io/address/" + address
                          : "https://bscscan.com/address/" + address
                      }
                    >
                      <LinkIcon size={16} />
                      <span style={{ marginLeft: "4px" }}>
                        View on Explorer
                      </span>
                    </AddressLink>
                  </AccountControl>
                </AccountGroupingRow>
              </InfoCard>
            </YourAccount>
          </AccountSection>
        </UpperSection>
      </ModalContent>
    </Modal>
  );
}
export default AccountInfoModal;
