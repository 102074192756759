import React, { useState } from "react";
import { HelpCircle } from "react-feather";
import usePoolData from "../hooks/usePoolData";
import usePoolsData from "../hooks/usePoolsData";
import Dmod from "../images/DmodToken.png";
import Pancake from "../images/pancake.png";
import Uniswap from "../images/uniswap.png";
import {
  ButtonText,
  DetailsButton,
  Epoch,
  FirstTableData,
  FirstTableDataHeading,
  LogoDiv,
  NameSpan,
  PoolRowTable,
  Table,
  TableButtonData,
  TableCard,
  TableCardDiv,
  TableCardRow,
  TableData,
  TableDiv,
  TableHeading,
  TableLogo,
  TableRow,
  TableStakeButton,
  TimeHeading,
  TimeSubHeading,
} from "../styles";
import { numberWithCommas, tokenBNtoNumber } from "../utils";

export default function MainTable({ web3, switchDMODModal, switchLPModal }) {
  const [
    provider,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    signer,
    address,
    network,
  ] = web3;
  const [
    currentEpochDMOD,
    dmodPrice,
    dmodAmountReserve,
    totalValueLocked,
    epochEndTimestamp,
    totalDistributedAmount,
    dmodRewardsDistributed,
    dmodUniLpPrice,
  ] = usePoolsData([
    provider,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    signer,
    address,
    network,
  ]);

  let [
    currentEpochDMODPool,
    rewardForCurrentEpochDMOD,
    poolBalanceDMOD,
    totalEpochDMOD,
    totalDistributedAmountDMOD,
    poolAprDMOD,
    updateDataPool,
  ] = usePoolData("dmod", [
    provider,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    signer,
    address,
    network,
  ]);

  let [
    currentEpochDMODLP,
    rewardForCurrentEpoch,
    poolBalanceLP,
    totalEpochDMODLP,
    totalDistributedAmountLP,
    poolAprLP,
    updateDataPoolLP,
  ] = usePoolData("dmodlp", [
    provider,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    signer,
    address,
    network,
  ]);

  const [formattedDuration, setFormattedDuration] = useState("00D 00H 00M 00S");

  const getFormattedDuration = () => {
    if (epochEndTimestamp) {
      const epochEndTimestampNum = epochEndTimestamp.toNumber();

      const duration = epochEndTimestampNum - Math.floor(new Date() / 1000);

      if (duration < 0) {
        return;
      }

      const day = parseInt(duration / 86400);
      const hour = parseInt((duration - day * 86400) / 3600);

      const minutes = parseInt((duration - (day * 86400 + hour * 3600)) / 60);
      const seconds = duration - (day * 86400 + hour * 3600 + minutes * 60);

      setFormattedDuration(`${day}D ${hour}H ${minutes}M ${seconds}S`);
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      getFormattedDuration();
    }, 1000);
  });

  return (
    <>
      <TableDiv>
        <Table style={{ width: "100%" }}>
          <TableRow>
            <TableHeading style={{ paddingLeft: "0rem" }}>Name</TableHeading>
            <TableHeading>Duration</TableHeading>
            <TableHeading>APR</TableHeading>
            <TableHeading>Total Staked</TableHeading>

            {/* <TimeSubHeading>16D:18H:44M</TimeSubHeading> */}
          </TableRow>

          <PoolRowTable>
            <FirstTableData style={{ display: "flex" }}>
              <LogoDiv>
                <TableLogo src={Dmod} />
              </LogoDiv>
              <LogoDiv style={{ position: "relative", left: "-10px" }}>
                <TableLogo src={Dmod} />
              </LogoDiv>
              <NameSpan>DMOD Pool</NameSpan>
            </FirstTableData>
            <TableData>30 Days</TableData>
            <TableData
              style={{ color: "#11CABE", paddingRight: 0, fontWeight: "bold" }}
            >
              {poolAprDMOD !== "Infinity" &&
              poolAprDMOD !== "NaN" &&
              poolAprDMOD ? (
                <>{poolAprDMOD + "%"}</>
              ) : (
                <span>{"No Deposits"}</span>
              )}
            </TableData>
            <TableData>
              $
              {numberWithCommas(
                (
                  tokenBNtoNumber(poolBalanceDMOD).toFixed(2) * dmodPrice
                ).toFixed(2)
              )}
            </TableData>

            <TableButtonData>
              <DetailsButton onClick={() => switchDMODModal(true)}>
                View Details
              </DetailsButton>
              <TableStakeButton onClick={() => switchDMODModal(true)}>
                <ButtonText>Stake Now</ButtonText>
              </TableStakeButton>
            </TableButtonData>
          </PoolRowTable>
          <PoolRowTable>
            <FirstTableData style={{ display: "flex" }}>
              <LogoDiv>
                <TableLogo src={Dmod} />
              </LogoDiv>
              {network === "homestead" ? (
                <LogoDiv style={{ position: "relative", left: "-10px" }}>
                  <TableLogo src={Uniswap} />
                </LogoDiv>
              ) : (
                <LogoDiv style={{ position: "relative", left: "-10px" }}>
                  <TableLogo src={Pancake} />
                </LogoDiv>
              )}
              <NameSpan>DMOD LP Pool</NameSpan>
            </FirstTableData>
            <TableData>30 Days</TableData>
            <TableData style={{ color: "#11CABE", fontWeight: "bold" }}>
              {poolAprLP !== "Infinity" && poolAprLP !== "NaN" && poolAprLP ? (
                <>{poolAprLP + "%"}</>
              ) : (
                <span>{"No Deposits"}</span>
              )}
            </TableData>
            <TableData>
              $
              {numberWithCommas(
                (
                  tokenBNtoNumber(poolBalanceLP).toFixed(2) * dmodUniLpPrice
                ).toFixed(2)
              )}
            </TableData>
            <TableButtonData>
              <DetailsButton onClick={() => switchLPModal(true)}>
                View Details
              </DetailsButton>
              <TableStakeButton onClick={() => switchLPModal(true)}>
                <ButtonText>Stake Now</ButtonText>
              </TableStakeButton>
            </TableButtonData>
          </PoolRowTable>
        </Table>
      </TableDiv>
      <TableCardDiv>
        <TableCard>
          <TableCardRow style={{ display: "flex", marginBottom: "2rem" }}>
            <FirstTableDataHeading style={{ display: "flex" }}>
              <LogoDiv>
                <TableLogo src={Dmod} />
              </LogoDiv>
              <LogoDiv>
                <TableLogo src={Dmod} />
              </LogoDiv>
              <NameSpan>DMOD Pool</NameSpan>
            </FirstTableDataHeading>
            <br />
            {/* {network === "bnb" || network === "homestead" ? (
              <>
                <TimeSubHeading
                  style={{
                    marginLeft: "2rem",
                    marginTop: "0.6rem",
                    fontSize: "12px",
                  }}
                >
                  {formattedDuration}
                </TimeSubHeading>
              </>
            ) : null} */}
          </TableCardRow>
          <TableCardRow>
            <Epoch style={{ float: "left", display: "flex", flexGrow: 1 }}>
              Total Staked
              <div style={{ marginLeft: "5px", marginTop: "1px" }}>
                <HelpCircle size="20" style={{ color: "#A6A0BB" }} />
              </div>
            </Epoch>
            <Epoch style={{ color: "#ffffff" }}>
              $
              {numberWithCommas(
                (
                  tokenBNtoNumber(poolBalanceDMOD).toFixed(2) * dmodPrice
                ).toFixed(2)
              )}
            </Epoch>
          </TableCardRow>
          <TableCardRow>
            <Epoch style={{ float: "left", display: "flex", flexGrow: 1 }}>
              Duration{" "}
              <div style={{ marginLeft: "5px", marginTop: "1px" }}>
                <HelpCircle size="20" style={{ color: "#A6A0BB" }} />
              </div>
            </Epoch>
            <Epoch style={{ color: "#ffffff" }}>30 Days </Epoch>
          </TableCardRow>
          <TableCardRow>
            <Epoch style={{ float: "left", display: "flex", flexGrow: 1 }}>
              APR{" "}
              <div style={{ marginLeft: "5px", marginTop: "1px" }}>
                <HelpCircle size="20" style={{ color: "#A6A0BB" }} />
              </div>
            </Epoch>
            <Epoch style={{ color: "#ffffff" }}>
              {" "}
              {poolAprDMOD !== "Infinity" &&
              poolAprDMOD !== "NaN" &&
              poolAprDMOD ? (
                <>{poolAprDMOD + "%"}</>
              ) : (
                <span>{"No Deposits"}</span>
              )}{" "}
            </Epoch>
          </TableCardRow>
          <TableCardRow style={{ marginTop: "2rem" }}>
            <DetailsButton
              style={{ minWidth: "10rem" }}
              onClick={() => switchDMODModal(true)}
            >
              View Details
            </DetailsButton>
            <TableStakeButton
              style={{ minWidth: "10rem" }}
              onClick={() => switchDMODModal(true)}
            >
              <ButtonText>Stake Now</ButtonText>
            </TableStakeButton>
          </TableCardRow>
        </TableCard>
        <TableCard>
          <TableCardRow style={{ marginBottom: "2rem" }}>
            <FirstTableDataHeading style={{ display: "flex" }}>
              <LogoDiv>
                <TableLogo src={Dmod} />
              </LogoDiv>
              {network === "homestead" ? (
                <LogoDiv>
                  <TableLogo src={Uniswap} />
                </LogoDiv>
              ) : (
                <LogoDiv>
                  <TableLogo src={Pancake} />
                </LogoDiv>
              )}
              <NameSpan>DMOD LP Pool</NameSpan>
            </FirstTableDataHeading>
            <br />
            {/* {network === "bnb" || network === "homestead" ? (
              <>
                <TimeSubHeading
                  style={{
                    marginLeft: "2rem",
                    marginTop: "0.6rem",
                    fontSize: "12px",
                  }}
                >
                  {formattedDuration}
                </TimeSubHeading>
              </>
            ) : null} */}
          </TableCardRow>
          <TableCardRow>
            <Epoch style={{ float: "left", display: "flex", flexGrow: 1 }}>
              Total Staked
              <div style={{ marginLeft: "5px", marginTop: "1px" }}>
                <HelpCircle size="20" style={{ color: "#A6A0BB" }} />
              </div>
            </Epoch>
            <Epoch style={{ color: "#ffffff" }}>
              $
              {numberWithCommas(
                (
                  tokenBNtoNumber(poolBalanceLP).toFixed(2) * dmodUniLpPrice
                ).toFixed(2)
              )}
            </Epoch>
          </TableCardRow>
          <TableCardRow>
            <Epoch style={{ float: "left", display: "flex", flexGrow: 1 }}>
              Duration{" "}
              <div style={{ marginLeft: "5px", marginTop: "1px" }}>
                <HelpCircle size="20" style={{ color: "#A6A0BB" }} />
              </div>
            </Epoch>
            <Epoch style={{ color: "#ffffff" }}>30 Days </Epoch>
          </TableCardRow>
          <TableCardRow>
            <Epoch style={{ float: "left", display: "flex", flexGrow: 1 }}>
              APR{" "}
              <div style={{ marginLeft: "5px", marginTop: "1px" }}>
                <HelpCircle size="20" style={{ color: "#A6A0BB" }} />
              </div>
            </Epoch>
            <Epoch style={{ color: "#ffffff" }}>
              {" "}
              {poolAprLP !== "Infinity" && poolAprLP !== "NaN" && poolAprLP ? (
                <>{poolAprLP + "%"}</>
              ) : (
                <span>{"No Deposits"}</span>
              )}
            </Epoch>
          </TableCardRow>
          <TableCardRow style={{ marginTop: "2rem" }}>
            <DetailsButton
              style={{ minWidth: "10rem" }}
              onClick={() => switchLPModal(true)}
            >
              View Details
            </DetailsButton>
            <TableStakeButton
              style={{ minWidth: "10rem" }}
              onClick={() => switchLPModal(true)}
            >
              <ButtonText>Stake Now</ButtonText>
            </TableStakeButton>
          </TableCardRow>
        </TableCard>
      </TableCardDiv>
    </>
  );
}
