import React from "react";
import PoolCard from "./PoolCard";

function Cards({
  web3,
  switchDMODModal,
  switchLPModal,
  showModalDMOD,
  showModalPoolLp,
}) {
  return (
    <>
      {/* <PoolsContainer> */}
      {/* <CardDiv> */}
      <PoolCard
        web3={web3}
        name={"DMOD"}
        pool={"dmod"}
        cardSubtitle={"Buy DMOD Here"}
        cardSubtitleTwo={"Stake DMOD, Earn more DMOD"}
        showModal={showModalDMOD}
        switchModal={switchDMODModal}
      />
      <PoolCard
        web3={web3}
        name={"DMOD LP"}
        pool={"dmodlp"}
        cardSubtitle={"Provide DMOD Liquidity Here"}
        cardSubtitleTwo={"Stake DMOD-LP Token, Earn $DMOD"}
        showModal={showModalPoolLp}
        switchModal={switchLPModal}
      />
      {/* </CardDiv> */}
      {/* </PoolsContainer> */}
    </>
  );
}
export default Cards;
