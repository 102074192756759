import React from "react";
import {
  DropdownOverlay,
  DropdownBox,
  DropdownHook,
  DropdownItem,
  NetworkIcon,
  NetworkTitle,
} from "../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Eth from "../images/ethereum.svg";
import Bsc from "../images/bridge.svg";
function NetworkDropdown({ setShowDropdown, switchNetwork }) {
  return (
    <>
      <DropdownOverlay
        onClick={(e) => setShowDropdown(false)}
      ></DropdownOverlay>
      <DropdownBox>
        <DropdownHook></DropdownHook>
        <DropdownItem onClick={(e) => switchNetwork("bsc")}>
          <NetworkIcon src={Bsc}></NetworkIcon>
          <NetworkTitle>BSC</NetworkTitle>
          {window.ethereum.chainId === "0x38" && (
            <FontAwesomeIcon icon={faCheck} color="green" />
          )}
        </DropdownItem>
        <DropdownItem onClick={(e) => switchNetwork("eth")}>
          <NetworkIcon src={Eth}></NetworkIcon>
          <NetworkTitle>Ethereum</NetworkTitle>
          {window.ethereum.chainId === "0x1" && (
            <FontAwesomeIcon icon={faCheck} color="green" />
          )}
        </DropdownItem>
      </DropdownBox>
    </>
  );
}
export default NetworkDropdown;
