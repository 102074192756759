import erc20Abi from "./abis/erc20.json";
import ownableAbi from "./abis/ownable.json";
import communityVaultAbi from "./abis/CommunityVault.json";
import stakingAbi from "./abis/Staking.json";
import yieldFarmAbi from "./abis/YieldFarm.json";
import UniswapV2Router02 from "./abis/UniswapV2Router02.json";

const abis = {
  erc20: erc20Abi,
  ownable: ownableAbi,
  communityVault: communityVaultAbi,
  staking: stakingAbi,
  yieldFarm: yieldFarmAbi,
  uniswapRouter: UniswapV2Router02,
};

export default abis;
