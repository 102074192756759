import { Web3Provider } from "@ethersproject/providers";
import { ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { getToken } from "../contracts";
import { web3Modal } from "../utils/web3Modal";

// Enter a valid infura key here to avoid being rate limited
// You can get a key for free at https://infura.io/register

const NETWORK_NAME = "homestead";

function useWeb3Modal(config = {}) {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  const [autoLoaded, setAutoLoaded] = useState(false);
  const [currentNetwork, setCurrentNetwork] = useState(NETWORK_NAME);
  const [dmodBalance, setDmodBalance] = useState(0);

  const { autoLoad = true } = config;

  // Web3Modal also supports many other wallets.
  // You can see other options at https://github.com/Web3Modal/web3modal

  // Open wallet selection modal.
  const loadWeb3Modal = useCallback(async () => {
    try {
      const newProvider = await web3Modal.connect();
      newProvider.on("chainChanged", (chainId) => {
        loadWeb3Modal();
      });
      newProvider.on("accountsChanged", (chainId) => {
        loadWeb3Modal();
      });
      const provider = new Web3Provider(newProvider);
      setProvider(provider);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const logoutOfWeb3Modal = useCallback(function() {
    web3Modal.clearCachedProvider();
    window.location.reload();
  }, []);

  useEffect(() => {
    const getProviderDetails = async () => {
      setSigner(provider.getSigner());
      const address = await provider.getSigner().getAddress();
      setUserAddress(address);

      const network = (await provider.getNetwork()).name;
      setCurrentNetwork(network);

      const token = await getToken(provider, provider.getSigner());
      if (!token) {
        return;
      }
      const balance = await token.balanceOf(address);
      setDmodBalance(parseFloat(ethers.utils.formatEther(balance)).toFixed(0));
    };

    if (provider) {
      getProviderDetails();
    }
  }, [provider]);

  // If autoLoad is enabled and the the wallet had been loaded before, load it automatically now.
  useEffect(() => {
    if (autoLoad && !autoLoaded && web3Modal.cachedProvider) {
      setAutoLoaded(true);
      loadWeb3Modal();
    }
  }, [autoLoad, autoLoaded, loadWeb3Modal]);

  return [
    provider,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    signer,
    userAddress,
    currentNetwork,
    dmodBalance,
  ];
}

export default useWeb3Modal;
