import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Authereum from "authereum";

const INFURA_ID = "7dbe1e2ae1134864b058834d381c2207";

export const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions: {
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                infuraId: INFURA_ID,
            },
        },
        authereum: {
            package: Authereum, // required
        },
    },
    network: "mainnet",
}); 