import { ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { getYieldFarmLP, getYieldFarmToken } from "../contracts";
import {
  calcTotalAmountPerEpoch,
  deprecationPerEpochDMOD,
  deprecationPerEpochLP,
  GENESIS_EPOCH_AMOUNT_DMOD,
  GENESIS_EPOCH_AMOUNT_LP,
  tokenBNtoNumber,
  tokenToBn,
  ZERO,
} from "../utils";
import usePoolsData from "./usePoolsData";

// const GENESIS_EPOCH_AMOUNT_DMOD = 30000;
// const GENESIS_EPOCH_AMOUNT_LP = 35000;
// const deprecationPerEpochDMOD = 100;
// const deprecationPerEpochLP = 100;

function usePoolData(
  pool,
  [provider, loadWeb3Modal, logoutOfWeb3Modal, signer, address, network]
) {
  const [currentEpochDMOD, setCurrentEpochDMOD] = useState(ZERO);
  const [rewardForCurrentEpoch, setRewardForCurrentEpoch] = useState(ZERO);
  const [poolBalance, setPoolBalance] = useState(ZERO);
  const [totalEpochDMOD, setTotalEpochDMOD] = useState(ZERO);
  const [totalDistributedAmount, setTotalDistributedAmount] = useState(ZERO);
  const [poolApr, setPoolApr] = useState(null);
  const [
    currentEpochDMODOngoing,
    dmodPrice,
    dmodAmountReserve,
    totalValueLocked,
    epochEndTimestamp,
    totalDistributedAmountDmod,
    dmodRewardsDistributed,
    dmodUniLpPrice,
  ] = usePoolsData([
    provider,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    signer,
    address,
    network,
  ]);

  let ratio = dmodUniLpPrice / dmodPrice;

  const getData = useCallback(
    async (currentProvider) => {
      let yieldFarming;

      let defaultProvider;

      if (!currentProvider) {
        // defaultProvider = getDefaultProvider(
        //   "https://mainnet.infura.io/v3/7dbe1e2ae1134864b058834d381c2207"
        // );
        return;
      } else {
        const currentNetwork = (await currentProvider.getNetwork()).name;
        if (currentNetwork === "bnb" || currentNetwork === "homestead") {
          defaultProvider = currentProvider;
        } else {
          // defaultProvider = getDefaultProvider(
          //   "https://mainnet.infura.io/v3/7dbe1e2ae1134864b058834d381c2207"
          // );

          return;
        }
      }

      const originalNetwork = provider
        ? (await provider.getNetwork()).name
        : "homestead";
      const defaultNetwork = (await defaultProvider.getNetwork()).name;

      if (pool === "dmod") {
        yieldFarming = await getYieldFarmToken(defaultProvider);
      }
      if (pool === "dmodlp") {
        yieldFarming = await getYieldFarmLP(defaultProvider);
      }

      const currentEpochDMOD = await yieldFarming.getCurrentEpoch();
      setCurrentEpochDMOD(currentEpochDMOD);
      const totalEpochDMOD = await yieldFarming.NR_OF_EPOCHS();
      setTotalEpochDMOD(totalEpochDMOD);
      const totalDistributedAmount = await yieldFarming.TOTAL_DISTRIBUTED_AMOUNT();
      setTotalDistributedAmount(totalDistributedAmount);

      let genesisEpochAmount, deprecationPerEpoch;

      if (pool === "dmod") {
        genesisEpochAmount = tokenToBn(
          ethers.BigNumber.from(GENESIS_EPOCH_AMOUNT_DMOD)
        );
        deprecationPerEpoch = tokenToBn(
          ethers.BigNumber.from(deprecationPerEpochDMOD)
        );
      }
      if (pool === "dmodlp") {
        genesisEpochAmount = tokenToBn(
          ethers.BigNumber.from(GENESIS_EPOCH_AMOUNT_LP)
        );
        deprecationPerEpoch = tokenToBn(
          ethers.BigNumber.from(deprecationPerEpochLP)
        );
      }

      const rewardForCurrentEpoch = calcTotalAmountPerEpoch(
        genesisEpochAmount,
        currentEpochDMOD,
        deprecationPerEpoch
      );

      setRewardForCurrentEpoch(rewardForCurrentEpoch);

      const poolBalance = await yieldFarming.getPoolSize(
        currentEpochDMOD.add(1)
      );

      setPoolBalance(poolBalance);

      let annualReward = ethers.BigNumber.from(0);

      for (
        var i = currentEpochDMOD.toNumber();
        i <= currentEpochDMOD.toNumber() + 12;
        i++
      ) {
        const epochAmount = calcTotalAmountPerEpoch(
          genesisEpochAmount,
          ethers.BigNumber.from(i),
          deprecationPerEpoch
        );

        annualReward = annualReward.add(epochAmount);
      }

      let apr;

      if (pool === "dmod") {
        apr =
          (
            tokenBNtoNumber(annualReward) /
            ethers.utils.formatEther(poolBalance)
          ).toFixed(2) * 100;
      }
      if (pool === "dmodlp") {
        apr =
          (
            tokenBNtoNumber(annualReward) /
            (ethers.utils.formatEther(poolBalance) * ratio)
          ).toFixed(2) * 100;
      }

      setPoolApr(apr.toFixed(2));
    },
    [ratio, pool, provider]
  );

  useEffect(() => {
    getData(provider);
  }, [getData, provider]);

  const updateData = () => {
    getData();
  };

  return [
    currentEpochDMOD,
    rewardForCurrentEpoch,
    poolBalance,
    totalEpochDMOD,
    totalDistributedAmount,
    poolApr,
    updateData,
  ];
}

export default usePoolData;
