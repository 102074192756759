import { useEffect, useState } from "react";

const usePrice = (id) => {
  const [price, setPrice] = useState(0)

  function fetchPrice(_id) {
    return fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${_id}&vs_currencies=usd`, {
      headers: {
        'cache-control': 'max-age=30,public,must-revalidate,s-maxage=60',
        'content-type': 'application/json; charset=utf-8 '
      }
    })
      .then(res => res.json())
      .catch(error => { })
  }

  useEffect(() => {
    fetchPrice(id).then(resp => {
      if (!resp) {
        return
      }
      setPrice(resp[id].usd)
    })
  }, [id])

  return price;
};

export default usePrice;
