import { abis, addresses } from "@project/contracts";
import { Contract } from "ethers";

export const getYieldFarmToken = async (provider, signer, somethin) => {
  try {
    if (!provider) {
      return;
    }
    const network = (await provider.getNetwork()).name;

    let contract;
    if (signer) {
      contract = new Contract(
        addresses[network].yieldFarmDmod,
        abis.yieldFarm,
        signer
      );
    } else {
      contract = new Contract(
        addresses[network].yieldFarmDmod,
        abis.yieldFarm,
        provider
      );
    }

    return contract;
  } catch (error) {
    console.log(error);
  }
};

export const getYieldFarmLP = async (provider, signer) => {
  try {
    if (!provider) {
      return;
    }
    const network = (await provider.getNetwork()).name;

    let contract;
    if (signer) {
      contract = new Contract(
        addresses[network].yieldFarmDmodLP,
        abis.yieldFarm,
        signer
      );
    } else {
      contract = new Contract(
        addresses[network].yieldFarmDmodLP,
        abis.yieldFarm,
        provider
      );
    }

    return contract;
  } catch (error) {
    console.log(error);
  }
};

export const getStaking = async (provider, signer) => {
  try {
    if (!provider) {
      return;
    }
    const network = (await provider.getNetwork()).name;

    let contract;
    if (signer) {
      contract = new Contract(addresses[network].staking, abis.staking, signer);
    } else {
      contract = new Contract(
        addresses[network].staking,
        abis.staking,
        provider
      );
    }

    return contract;
  } catch (error) {
    console.log(error);
  }
};

export const getToken = async (provider, signer) => {
  try {
    if (!provider) {
      return;
    }
    const network = (await provider.getNetwork()).name;

    let contract;
    if (signer) {
      contract = new Contract(addresses[network].dmodToken, abis.erc20, signer);
    } else {
      contract = new Contract(
        addresses[network].dmodToken,
        abis.erc20,
        provider
      );
    }

    return contract;
  } catch (error) {
    console.log(error);
  }
};

export const getLPToken = async (provider, signer) => {
  try {
    if (!provider) {
      return;
    }
    const network = (await provider.getNetwork()).name;

    let contract;
    if (signer) {
      contract = new Contract(
        addresses[network].dmodTokenLP,
        abis.erc20,
        signer
      );
    } else {
      contract = new Contract(
        addresses[network].dmodTokenLP,
        abis.erc20,
        provider
      );
    }

    return contract;
  } catch (error) {
    console.log(error);
  }
};

export const getUniRouter = async (provider, signer) => {
  try {
    if (!provider) {
      return;
    }
    const network = (await provider.getNetwork()).name;

    let contract;
    if (signer) {
      contract = new Contract(
        addresses[network].uniswapRouter,
        abis.uniswapRouter,
        signer
      );
    } else {
      contract = new Contract(
        addresses[network].uniswapRouter,
        abis.uniswapRouter,
        provider
      );
    }

    return contract;
  } catch (error) {
    console.log(error);
  }
};
