import { faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Modal from "react-modal";
import Approve from "../../images/Approve.png";
import Cube from "../../images/Cube.png";
import Failed from "../../images/Failed.png";
import {
  ApproveImg,
  FailedPara,
  ImgDiv,
  PendingImg,
  StakeButton,
  SubNumberSpan,
} from "../../styles";

function TransactionModal({
  modalIsOpen,
  closeModal,
  customStyles,
  transactionData,
  network,
}) {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Pool Modal"
      shouldCloseOnOverlayClick={true}
    >
      {transactionData ? (
        <>
          <center>
            {transactionData.status === "failed" ? null : (
              <h2 style={{ marginTop: "0" }}>
                {transactionData.type + " Transaction"}
              </h2>
            )}

            {transactionData.status === "pending" ? (
              <PendingImg className="pending-cube" src={Cube} />
            ) : null}

            {transactionData.status === "waiting for approval on metamask" ? (
              <PendingImg className="pending-cube" src={Cube} />
            ) : null}

            {transactionData.status === "success" ? (
              <>
                <ImgDiv />
                <ApproveImg src={Approve} />
              </>
            ) : null}
            {transactionData.status === "failed" ? (
              <>
                <ImgDiv />
                <ApproveImg failed src={Failed} />
              </>
            ) : null}
            {transactionData.status === "failed" ? (
              <h2>Transaction Failed</h2>
            ) : (
              <p style={{ marginTop: "1rem", marginBottom: "0" }}>
                Your transaction is {transactionData.status}
              </p>
            )}
            {transactionData.status === "failed" ? (
              <FailedPara>
                Error Transaction was not mined within 100 blocks, please make
                sure your transaction was properly sent. Be aware that it might
                still be mined!
              </FailedPara>
            ) : null}

            {transactionData.tx ? (
              <div
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  if (network === "homestead") {
                    window.open(
                      "https://etherscan.io/tx/" + transactionData.tx.hash,
                      "_blank",
                      "noopener"
                    );
                  } else {
                    window.open(
                      "https://bscscan.com/tx/" + transactionData.tx.hash,
                      "_blank",
                      "noopener"
                    );
                  }
                }}
              >
                {transactionData.status === "failed" ? null : (
                  <span>
                    <SubNumberSpan>See Transaction</SubNumberSpan>
                    <span>
                      <FontAwesomeIcon
                        icon={faExternalLinkSquareAlt}
                        size="xs"
                        color="#ff9a36"
                        style={{ marginLeft: "0.3rem" }}
                      />
                    </span>
                  </span>
                )}
              </div>
            ) : null}
            {transactionData.status === "success" ? (
              // <ModalButton style={{ marginTop: "1rem" }} onClick={closeModal}>
              //   Okay
              // </ModalButton>
              <StakeButton style={{ marginTop: "1rem" }} onClick={closeModal}>
                Okay
              </StakeButton>
            ) : (
              <></>
            )}
            {transactionData.status === "failed" ? (
              // <ModalButton style={{ marginTop: "1rem" }} onClick={closeModal}>
              //   Okay
              // </ModalButton>
              <StakeButton onClick={closeModal}>Okay</StakeButton>
            ) : (
              <></>
            )}
          </center>
        </>
      ) : null}
    </Modal>
  );
}
export default TransactionModal;
