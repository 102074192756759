import { getDefaultProvider } from "@ethersproject/providers";
import { addresses } from "@project/contracts";
import { ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";
import {
  getToken,
  getUniRouter,
  getYieldFarmLP,
  getYieldFarmToken,
} from "../contracts";
import {
  calcTotalAmountPerEpoch,
  deprecationPerEpochDMOD,
  deprecationPerEpochLP,
  GENESIS_EPOCH_AMOUNT_DMOD,
  GENESIS_EPOCH_AMOUNT_LP,
  ONE,
  tokenBNtoNumber,
  tokenToBn,
  ZERO,
} from "../utils";

function usePoolsData([
  provider,
  loadWeb3Modal,
  logoutOfWeb3Modal,
  signer,
  address,
  network,
]) {
  const [currentEpochDMOD, setCurrentEpochDMOD] = useState(ZERO);
  const [dmodPrice, setDmodPrice] = useState(ZERO);
  const [dmodAmountReserve, setDmodAmountReserve] = useState(ZERO);
  const [totalValueLocked, setTotalValueLocked] = useState(0);
  const [epochEndTimestamp, setEpochEndTimestamp] = useState(ZERO);
  const [totalDistributedAmount, setTotalDistributedAmount] = useState(ZERO);
  const [dmodRewardsDistributed, setDmodRewardsDistributed] = useState(ZERO);
  const [dmodUniLpPrice, setDmodUniLpPrice] = useState();

  const getData = useCallback(async () => {
    const getDmodRewardsDistributed = async () => {
      let defaultProvider;
      if (provider && (network === "bnb" || network === "homestead")) {
        defaultProvider = provider;
      } else {
        defaultProvider = getDefaultProvider(
          "https://mainnet.infura.io/v3/7dbe1e2ae1134864b058834d381c2207"
        );
      }

      const yieldFarmingDMOD = await getYieldFarmToken(defaultProvider);
      if (!yieldFarmingDMOD) {
        return;
      }
      const yieldFarmingLP = await getYieldFarmLP(defaultProvider);

      const currentEpochLP = await yieldFarmingDMOD.getCurrentEpoch();

      let pushPoolRewardsDistributed = ZERO;
      let lpPoolRewardsDistributed = ZERO;

      for (var i = 1; i < currentEpochLP.toNumber(); i++) {
        const rewardForCurrentEpochLP = calcTotalAmountPerEpoch(
          ethers.BigNumber.from(GENESIS_EPOCH_AMOUNT_LP),
          ethers.BigNumber.from(i),
          ethers.BigNumber.from(deprecationPerEpochLP)
        );

        lpPoolRewardsDistributed = lpPoolRewardsDistributed.add(
          rewardForCurrentEpochLP
        );

        const rewardForCurrentEpochPUSH = calcTotalAmountPerEpoch(
          ethers.BigNumber.from(GENESIS_EPOCH_AMOUNT_DMOD),
          ethers.BigNumber.from(i),
          ethers.BigNumber.from(deprecationPerEpochDMOD)
        );

        pushPoolRewardsDistributed = pushPoolRewardsDistributed.add(
          rewardForCurrentEpochPUSH
        );
      }

      return pushPoolRewardsDistributed.add(lpPoolRewardsDistributed);
    };
    try {
      let defaultProvider;
      if (provider && (network === "bnb" || network === "homestead")) {
        defaultProvider = provider;
      } else {
        return;
      }

      const yieldFarmingDMOD = await getYieldFarmToken(
        defaultProvider,
        null,
        "asdfasdfasd"
      );
      if (!yieldFarmingDMOD) {
        return;
      }

      const yieldFarmingLP = await getYieldFarmLP(defaultProvider);
      const uniswapV2Router02 = await getUniRouter(defaultProvider);

      const currentEpochDMOD = await yieldFarmingDMOD.getCurrentEpoch();

      setCurrentEpochDMOD(currentEpochDMOD);

      const dmodPriceAmounts = await uniswapV2Router02.getAmountsOut(
        tokenToBn(ONE),
        [
          addresses[network].dmodToken,
          addresses[network].WETHAddress,
          addresses[network].USDTAddress,
        ]
      );

      const dmodToken = await getToken(defaultProvider);

      const dmodPrice = parseFloat(
        ethers.utils.formatEther(dmodPriceAmounts[dmodPriceAmounts.length - 1])
      ).toFixed(4);
      // const dmodPrice = 0.33;
      setDmodPrice(dmodPrice);

      const dmodAmountReserve = tokenBNtoNumber(
        await dmodToken.balanceOf(addresses[network].dmodTokenLP)
      );
      setDmodAmountReserve(dmodAmountReserve);

      const wethAmountReserve = tokenBNtoNumber(
        await dmodToken
          .attach(addresses[network].WETHAddress)
          .balanceOf(addresses[network].dmodTokenLP)
      ); // Using dmodToken instance for WETH instance

      const ethPriceAmounts = await uniswapV2Router02.getAmountsOut(
        ONE.toString(),
        [addresses[network].WETHAddress, addresses[network].USDTAddress]
      );
      const ethPrice =
        ethPriceAmounts[ethPriceAmounts.length - 1].toNumber() / 1000000;

      const uniTotalSupply = tokenBNtoNumber(
        await dmodToken.attach(addresses[network].dmodTokenLP).totalSupply()
      ); // Using dmodToken instance for Uni-V2 instance

      const uniLpPrice =
        (dmodAmountReserve * dmodPrice + wethAmountReserve * ethPrice) /
        uniTotalSupply;
      setDmodUniLpPrice(uniLpPrice);

      const dmodNextPoolSize = tokenBNtoNumber(
        await yieldFarmingDMOD.getPoolSize(currentEpochDMOD.add(1))
      );
      const lpNextPoolSize = tokenBNtoNumber(
        await yieldFarmingLP.getPoolSize(currentEpochDMOD.add(1))
      );

      const totalValueLocked =
        dmodNextPoolSize * dmodPrice + lpNextPoolSize * uniLpPrice;
      setTotalValueLocked(totalValueLocked);

      const epochDuration = await yieldFarmingDMOD.epochDuration();

      const epochStart = await yieldFarmingDMOD.epochStart();

      const start = epochStart.add(currentEpochDMOD.sub(1).mul(epochDuration));

      const epochEndTimestamp = start.add(epochDuration);
      setEpochEndTimestamp(epochEndTimestamp);

      const dmodTotalDistributedAmount = await yieldFarmingDMOD.TOTAL_DISTRIBUTED_AMOUNT();
      const lpTotalDistributedAmount = await yieldFarmingLP.TOTAL_DISTRIBUTED_AMOUNT();

      const totalDistributedAmount = dmodTotalDistributedAmount.add(
        lpTotalDistributedAmount
      );
      setTotalDistributedAmount(totalDistributedAmount);

      const dmodRewardsDistributed = await getDmodRewardsDistributed();

      setDmodRewardsDistributed(dmodRewardsDistributed);
    } catch (error) {
      console.log(error);
    }
  }, [provider, network]);

  useEffect(() => {
    getData();
  }, [getData]);

  return [
    currentEpochDMOD,
    dmodPrice,
    dmodAmountReserve,
    totalValueLocked,
    epochEndTimestamp,
    totalDistributedAmount,
    dmodRewardsDistributed,
    dmodUniLpPrice,
  ];
}

export default usePoolsData;
