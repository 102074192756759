import React, { useState } from "react";
import usePoolsData from "../hooks/usePoolsData";
import IconOne from "../images/icon1.png";
import IconTwo from "../images/icon2.png";
import IconThree from "../images/icon3.png";
import {
  BodyCol,
  BodyMain,
  Box,
  BoxDiv,
  Col,
  HeadingDiv,
  IconImg,
  LightSpan,
  NavDiv,
  NumberDiv,
  NumberSpan,
  SectionRow,
  SubTitle,
  TimeHeading,
  TimeSubHeading,
  Title,
} from "../styles";
import { numberWithCommas } from "../utils";

function Section({ web3 }) {
  const [
    provider,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    signer,
    address,
    network,
  ] = web3;

  const [
    currentEpochDMOD,
    dmodPrice,
    dmodAmountReserve,
    totalValueLocked,
    epochEndTimestamp,
    totalDistributedAmount,
    dmodRewardsDistributed,
  ] = usePoolsData([
    provider,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    signer,
    address,
    network,
  ]);
  const [formattedDuration, setFormattedDuration] = useState(
    "00D : 00H : 00M : 00S"
  );

  const getFormattedDuration = () => {
    if (epochEndTimestamp) {
      const epochEndTimestampNum = epochEndTimestamp.toNumber();

      const duration = epochEndTimestampNum - Math.floor(new Date() / 1000);

      if (duration < 0) {
        return;
      }

      const day = parseInt(duration / 86400);
      const hour = parseInt((duration - day * 86400) / 3600);

      const minutes = parseInt((duration - (day * 86400 + hour * 3600)) / 60);
      const seconds = duration - (day * 86400 + hour * 3600 + minutes * 60);

      setFormattedDuration(`${day}D : ${hour}H : ${minutes}M : ${seconds}S`);
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      getFormattedDuration();
    }, 1000);
  });
  return (
    <NavDiv>
      <SectionRow>
        <Col>
          <BodyMain>
            <Title>Stake Rewards With Demodyfi!</Title>

            <SubTitle>
              Stake <span style={{ fontWeight: "bold" }}>DMOD</span>,
              <span style={{ fontWeight: "bold" }}> DMOD-UNI</span> or{" "}
              <span style={{ fontWeight: "bold" }}>DMOD-BNB</span> and{" "}
              <span style={{ fontWeight: "bold" }}>earn DMOD</span> in return
            </SubTitle>
            <TimeHeading>Total Time Left To Claim Awards</TimeHeading>
            <br />
            <div style={{ justifyContent: "center", display: "flex" }}>
              {network === "bnb" || network === "homestead" ? (
                <>
                  <TimeSubHeading>{formattedDuration}</TimeSubHeading>
                </>
              ) : null}
            </div>
          </BodyMain>
        </Col>
        <BodyCol>
          <BoxDiv>
            <Box style={{ marginLeft: "0rem" }}>
              <NumberDiv>
                <IconImg src={IconOne} />
                <HeadingDiv>TOTAL VALUE LOCKED</HeadingDiv>
                <NumberSpan>
                  {network === "bnb" || network === "homestead" ? (
                    <>${numberWithCommas(totalValueLocked.toFixed(2))}</>
                  ) : (
                    <span style={{ fontSize: "12px", color: "white" }}>
                      Network Not Supported
                    </span>
                  )}
                </NumberSpan>
              </NumberDiv>
              {/* <LightSpan>TVL</LightSpan> */}
            </Box>
            <Box>
              <NumberDiv>
                <IconImg src={IconTwo} />
                <HeadingDiv>DMOD REWARDS GIVEN</HeadingDiv>
                {network === "bnb" || network === "homestead" ? (
                  <>
                    <NumberSpan>
                      {numberWithCommas(dmodRewardsDistributed).toString()}
                    </NumberSpan>
                    {/* <SubNumberSpan>
                      out of{" "}
                      {numberWithCommas(
                        tokenBNtoNumber(totalDistributedAmount).toFixed(0)
                      )}
                    </SubNumberSpan> */}
                  </>
                ) : (
                  <span style={{ fontSize: "12px", color: "white" }}>
                    Network Not Supported
                  </span>
                )}
              </NumberDiv>
              {/* <LightSpan>REWARDED</LightSpan> */}
            </Box>
            {/* <Box>
             
              <NumberDiv>
              <HeadingDiv>TIME LEFT</HeadingDiv>
                {network === "bnb" || network === "homestead" ? (
                  <>
                    <NumberSpan>{formattedDuration}</NumberSpan>
                    <SubNumberSpan>until next epoch</SubNumberSpan>
                  </>
                ) : (
                  <span style={{ fontSize: "12px", color: "white" }}>
                    Network Not Supported
                  </span>
                )}
              </NumberDiv>
              <LightSpan>TIME LEFT</LightSpan>
            </Box> */}
            <Box>
              <NumberDiv>
                <IconImg src={IconThree} />
                <HeadingDiv>DMOD PRICE</HeadingDiv>
                {network === "bnb" || network === "homestead" ? (
                  <>
                    <NumberSpan>{dmodPrice.toString()} $</NumberSpan>
                  </>
                ) : (
                  <span style={{ fontSize: "12px", color: "white" }}>
                    Network Not Supported
                  </span>
                )}
              </NumberDiv>
              {/* <LightSpan>
                {network === "homestead" ? "Uniswap" : "Pancakeswap"}
              </LightSpan> */}
              {/* <LightSpan>UNISWAP</LightSpan> */}
            </Box>
          </BoxDiv>
        </BodyCol>
      </SectionRow>
    </NavDiv>
  );
}
export default Section;
