import { ethers } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { getYieldFarmLP, getYieldFarmToken } from "../contracts";
import {
  calcTotalAmountPerEpoch,
  deprecationPerEpochDMOD,
  deprecationPerEpochLP,
  GENESIS_EPOCH_AMOUNT_DMOD,
  GENESIS_EPOCH_AMOUNT_LP,
  ZERO,
} from "../utils";

function useUserPoolsData(
  pool,
  [provider, loadWeb3Modal, logoutOfWeb3Modal, signer, address, currentNetwork]
) {
  const [potentialUserReward, setPotentialUserReward] = useState(0);
  const [epochStakeNext, setEpochStakeNext] = useState(ZERO);
  const [totalAccumulatedReward, setTotalAccumulatedReward] = useState(0);
  const [totalAvailableReward, setTotalAvailableReward] = useState(0);
  const [lastEpochIdHarvested, setLastEpochIdHarvested] = useState(0);

  const getData = useCallback(async () => {
    const calculateUserEpochReward = async (epochId, yieldFarming) => {
      if (epochId.toNumber() === 0) {
        return 0;
      }
      const epochStake = ethers.utils.parseEther(
        (
          await yieldFarming.getEpochStake(await signer.getAddress(), epochId)
        ).toString()
      );

      const poolSize = ethers.utils.parseEther(
        (await yieldFarming.getPoolSize(epochId)).toString()
      );

      let potentialUserReward = 0;
      let rewardForCurrentEpoch;
      if (pool === "dmod") {
        rewardForCurrentEpoch = calcTotalAmountPerEpoch(
          ethers.BigNumber.from(GENESIS_EPOCH_AMOUNT_DMOD),
          epochId,
          ethers.BigNumber.from(deprecationPerEpochDMOD)
        );
      }
      if (pool === "dmodlp") {
        rewardForCurrentEpoch = calcTotalAmountPerEpoch(
          ethers.BigNumber.from(GENESIS_EPOCH_AMOUNT_LP),
          epochId,
          ethers.BigNumber.from(deprecationPerEpochLP)
        );
      }

      if (poolSize > 0) {
        const rewardForCurrentEpochNum = rewardForCurrentEpoch.toNumber();

        potentialUserReward =
          (epochStake / poolSize) * rewardForCurrentEpochNum;
      }

      return potentialUserReward;
    };
    try {
      if (
        !signer ||
        !(currentNetwork === "bnb" || currentNetwork === "homestead")
      ) {
        return;
      }
      let yieldFarming;
      if (pool === "dmod") {
        yieldFarming = await getYieldFarmToken(signer.provider, signer);
      }
      if (pool === "dmodlp") {
        yieldFarming = await getYieldFarmLP(signer.provider, signer);
      }
      const currentEpochPUSH = await yieldFarming.getCurrentEpoch();

      const potentialUserReward = await calculateUserEpochReward(
        currentEpochPUSH,
        yieldFarming
      );
      setPotentialUserReward(potentialUserReward);

      const epochStakeNext = await yieldFarming.getEpochStake(
        await signer.getAddress(),
        currentEpochPUSH.add(1)
      );

      setEpochStakeNext(epochStakeNext);

      let totalAccumulatedReward = 0;
      for (var i = 1; i < currentEpochPUSH.toNumber(); i += 1) {
        const epochReward = await calculateUserEpochReward(
          ethers.BigNumber.from(i),
          yieldFarming
        );
        totalAccumulatedReward = totalAccumulatedReward + epochReward;
      }
      setTotalAccumulatedReward(totalAccumulatedReward);

      const lastEpochIdHarvested = await yieldFarming.userLastEpochIdHarvested();

      setLastEpochIdHarvested(lastEpochIdHarvested);

      let totalAvailableReward = 0;
      for (
        let i = lastEpochIdHarvested.toNumber() + 1;
        i < currentEpochPUSH.toNumber();
        i += 1
      ) {
        const epochReward = await calculateUserEpochReward(
          ethers.BigNumber.from(i),
          yieldFarming
        );

        totalAvailableReward = totalAvailableReward + epochReward;
      }
      setTotalAvailableReward(totalAvailableReward);
    } catch (error) {
      console.log(error);
    }
  }, [signer, currentNetwork, pool]);

  useEffect(() => {
    try {
      getData();
    } catch (error) {
      console.log(error);
    }
  }, [getData]);

  const updateData = () => {
    try {
      getData();
    } catch (error) {
      console.log(error);
    }
  };

  return [
    potentialUserReward,
    epochStakeNext,
    totalAccumulatedReward,
    totalAvailableReward,
    lastEpochIdHarvested,
    updateData,
  ];
}

export default useUserPoolsData;
