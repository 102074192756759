import { ethers } from "ethers";

export const tokenToBn = (token) => {
  return ethers.BigNumber.from(token).mul(
    ethers.BigNumber.from(10).pow(ethers.BigNumber.from(18))
  );
};

export const tokenBNtoNumber = (tokenBn) => {
  return (
    ethers.BigNumber.from(tokenBn)
      .div(ethers.BigNumber.from(10).pow(ethers.BigNumber.from(10)))
      .toNumber() / 100000000
  );
};

export const ONE = ethers.BigNumber.from(1);
export const ZERO = ethers.BigNumber.from(0);

export const GENESIS_EPOCH_AMOUNT_DMOD = 20000;
export const GENESIS_EPOCH_AMOUNT_LP = 20000;
export const deprecationPerEpochDMOD = 125;
export const deprecationPerEpochLP = 125;

export const calcTotalAmountPerEpoch = (
  genesisEpochAmount,
  epochId,
  deprecationPerEpoch
) => {
  return epochId.toNumber() === 0
    ? 0
    : genesisEpochAmount.sub(epochId.mul(deprecationPerEpoch));
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatAddress = (address) => {
  return address.slice(0, 6) + "..." + address.slice(-6);
};  