import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowRight, ExternalLink as LinkIconFeather, X } from "react-feather";
import { Button as RebassButton } from "rebass/styled-components";
import styled, { keyframes } from "styled-components";
import Top from "../images/bg2.png";
import faq from "../images/faq2.png";
import { media } from "./theme";

export const isNotDesktop = (screenType) =>
  screenType.isMobile || screenType.isTablet;
export const Header = styled.header`
  background-color: #282c34;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
`;
export const Body = styled.div`
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: calc(100vh - 70px);
`;

export const MainDiv = styled.div`
  min-height: calc(100vh - 70px);
  background: url(${Top});
  background-color: black;
  height: 100%;
  width: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
`;
export const Image = styled.img`
  height: 40vmin;
  margin-bottom: 16px;
  pointer-events: none;
`;

export const CustomLink = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: #61dafb;
  margin-top: 10px;
`;

export const Button = styled.button`
  background: linear-gradient(
      318.86deg,
      #563bd3 0%,
      rgba(123, 35, 195, 0) 53.86%
    ),
    radial-gradient(
      83.68% 83.68% at 0% 0%,
      #6fe38f 0%,
      rgba(133, 66, 244, 0.2) 95.83%
    );
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-weight: 100;
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12.5px;
  line-height: 14px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 7rem;
  height: 2.5rem;
  padding: 0.4rem 1.3rem;
  margin-left: -2rem;
  cursor: pointer;
  &:hover {
    background: linear-gradient(342deg, #6fe38f, rgba(133, 66, 244, 0.2) 53.86%),
      radial-gradient(
        8368% 83.68% at 0 0,
        #563bd3 0,
        rgba(123, 35, 195, 0) 150%
      );
  }
  ${media.tablet.max(`
 font-size: 9px;
 height:2rem;
 margin-right: 1rem;
    `)};
`;

export const NetworkContainer = styled.div`
  position: relative;
  ${media.tablet.max(`
   display: flex;
    `)};
`;

export const NetworkButton = styled.button`
  margin-right: 1rem;
  background: rgba(106, 121, 159, 0.18);
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 13px !important;
  border-radius: 5px;
  border: 0px solid #4a4a4e;
  color: white;
  cursor: pointer;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  text-align: center;
  text-transform: capitalize;

  /* Color / White */

  color: #ffffff;

  backdrop-filter: blur(4px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 25px;
  padding: 0.5rem 16px 0.5rem 8px;
  display: flex;
  align-items: center;
  ${media.tablet.max(`
  font-size: 7px;
  margin: 0;
  padding: 15px;
  margin-right: 2.5rem;
  border: 0;
  height: 2.4rem;
  background-color: rgba(106,121,159,0.18);
  border: 0px solid #4a4a4e;
    
    `)};
  ${media.largeMobile.max(`
  font-size: 7px;
  margin: 0;
  padding: 7px;
  margin-right: 2.5rem;
  border: 0;
  height: 2rem;
  background-color: rgba(106,121,159,0.18);
  border: 0px solid #4a4a4e;
    `)};
`;

export const NetworkItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  ${media.largeMobile.max(`
  margin-right: 0.3rem;
`)};
`;

export const Bullet = styled.span`
  width: 1rem;
  height: 1rem;
  background-color: red;
  border-radius: 50%;
`;
export const NetworkIcon = styled.img`
  border-radius: 6px;
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
  ${media.largeMobile.max(`
  margin-right: 0.5rem;
    `)};
`;

export const NetworkTitle = styled.span`
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12.5px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  background: linear-gradient(
    73.6deg,
    #91dcf0 6.19%,
    #25b0d7 25.05%,
    #fa8ffa 57.68%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${media.largeMobile.max(`
font-size: 8px;
    `)}
`;

export const DropdownOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
`;

export const DropdownBox = styled.div`
  position: absolute;
  right: 18px;
  top: 3.4em;
  width: 225px;
  padding: 3px 0px;
  display: flex;
  flex-direction: column;
  background: rgb(13 11 31);
  color: white;
  border-radius: 6px;
  opacity: 1.6;
  z-index: 1000;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  ${media.tablet.max(`
margin-top: 0.5rem;
position: absolute;
right: 30px;
top: 4.7em;
width: 225px;
padding: 3px 0px;
display: flex;
flex-direction: column;
background: black;
color: white;
border-radius: 6px;
opacity: 1.6;
z-index: 1000;
box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2),
  0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
`)};
  ${media.largeMobile.max(`
position: absolute;
right: 50px;
top: 4em;
width: 183px;
padding: 3px 0px;
display: flex;
flex-direction: column;
background: black;
color: white;
border-radius: 6px;
opacity: 1.6;
z-index: 1000;
box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2),
  0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
`)};
`;

export const DropdownHook = styled.div`
  display: block;
  z-index: -1;
  text-align: center;
  position: absolute;
  top: -7px;
  left: 195px;
  height: 18px;
  width: 18px;
  transform: rotate(45deg);
  background: rgb(13 11 31);
  border-radius: 2px;
  ${media.tablet.max(`
background: black;
`)};
  ${media.largeMobile.max(`
background: black;
top: -18px;
left: 160px;
`)};
`;

export const DropdownItem = styled.div`
  padding: 8px 20px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #05040f;
  }
`;

export const Imagediv = styled.div`
  display: flex;
  ${media.tablet.max(`flex-grow: 1;`)};
  ${media.largeMobile.max(`flex-grow: 1;`)};
  ${media.mobile.max(`flex-grow: 0;`)};
`;

export const MenuItem = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 1rem;
  color: #334061;
  opacity: 0.7;
  font-weight: bold;
  cursor: pointer;
  margin-right: 1rem;
  border-radius: 6px;
  text-decoration: none;
  background: ${(props) => (props.selected ? "#bdddf8" : "white")};
  &:hover {
    background: #bdddf8;
  }
`;

export const Logo = styled.img`
  height: 40px;
  cursor: pointer;
  ${media.tablet.max(`
         width: auto;
    height: 1.5rem;
    `)};
  ${media.mobile.max(`
        width: auto;
    height: 1.2rem;
    `)};
`;

export const BodyMain = styled.div`
  text-align: left;
  padding: 0rem 2rem;
  margin-top: 4rem;
  ${media.tablet.max(`
            margin-left: 9rem;
            padding: 0rem;

    `)};
`;
export const Main = styled.div`
  margin: -7rem 12rem;
  margin-bottom: 5rem;
  border-radius: 8px;
  position: relative;
  box-shadow: 0px 5px 100px -23px rgb(0 0 0 / 5%);
  padding: 2rem 5rem;
  padding-bottom: 4rem;

  background-color: white;
`;

export const Title = styled.h1`
  font-family: Poppins !important;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 130%;
  text-align: center;
  margin-left: 13rem;
  width: 23rem;
  /* or 39px */
  background: linear-gradient(
    73.6deg,
    #85ffc4 2.11%,
    #5cc6ff 42.39%,
    #bc85ff 85.72%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${media.tablet.max(`
         font-size: 44px;
         margin-left: 22rem;
    margin-top: -2rem;
    width: 29rem;
         
    `)};
  ${media.largeMobile.max(`
         font-size: 44px;
         margin-left: 17rem !important;
    margin-top: 0rem;
    width: 29rem;
         
    `)};
`;

export const SubTitle = styled.h5`
  font-family: Poppins !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  /* identical to box height, or 229% */
  text-align: center;

  color: #a6a0bb;

  ${media.tablet.max(`
         font-size: 14px;
    margin-top: 0;
    width: 18rem;
    margin-left: 27rem;    `)};
  ${media.largeMobile.max(`
         font-size: 14px;
    margin-top: 0;
    width: 14rem;
    width: 17rem;
    margin-left: 23rem !important;
    `)};
`;
export const CardSubTitle = styled.h5`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #f3f2f9;
  max-width: 32rem;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1rem;
  text-decoration: underline;
  ${media.tablet.max(`
         font-size: 14px;
    margin-top: 0;
    width: 18rem;
    `)};
  ${media.mobile.max(`
         font-size: 12px;
    margin-top: 0;
    width: 18rem;
    `)};
`;
export const Text = styled.h4`
  color: #334061;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.8;
`;

export const List = styled.h4`
  color: #334061;

  font-size: 20px;
  font-weight: normal;
  line-height: 1.8;
  margin-left: 2rem;
`;

export const FloatingDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
`;

export const FloatingInnerLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 600;
`;

export const FloatingInnerDiv = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
`;

export const FloatingSpan = styled.span`
  background: rgba(0, 0, 0, 0.32);
  padding: 4px 6px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 4px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
`;

export const FloatingImg = styled.img`
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
`;

export const Note = styled.div`
  text-align: center;
  color: #ee0902;
  font-size: 16px;
  font-weight: 600;
  margin-top: 3rem;
`;

export const ClaimButton = styled.button`
  margin-top: 1rem;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  font-size: 17px;
  padding: 16px 24px;
  background-color: #4977f9;
  color: white;
  transition: all ease 250ms;
  line-height: 20px;
  border-radius: 6px;
  outline: none !important;
  border: 1px solid #4977f9;
  box-shadow: 0px 4px 6px rgb(64 112 244 / 16%);

  cursor: pointer;
  min-width: 208px;
  &:hover {
    background-color: #2251d8;
    color: #fff;
  }
  &:disabled {
    background-color: #83a2f7 !important;
    border: 1px solid #83a2f7 !important;
    cursor: not-allowed !important;
  }
`;

export const ConnectButton = styled.button`
  padding: 12px 24px;
  margin: 2rem 3rem 2rem 1rem;
  font-size: 15px;
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  background-color: #f4f7ff;
  color: #4977f9;
  box-shadow: 0px 0px 0px rgb(0 0 0 / 4%);
  transition: all ease 250ms;
  line-height: 20px;
  border-radius: 6px;
  outline: none !important;
  border: 1px solid #4977f9;

  cursor: pointer;
  &:hover {
    background-color: #4977f9;
    color: #fff;
  }
`;

export const BadgeButton = styled.button`
  background: #8247e5;
  color: white;
  border: none;
  border-radius: 13px;
  padding: 0.4rem 1rem;
  font-weight: bold;
`;

export const Bold = styled.span`
  font-weight: normal;
  color: #11c264;

  font-size: 20px;
  font-weight: 600;
`;

// export const AccordionDiv = styled(Accordion)`
//   margin-top: 3rem;
//
// `;

// export const AccordionItemDiv = styled(AccordionItem)`
//   margin-top: 2rem;
//   font-size: 20px;
//   color: #525f80;
//   font-weight: normal;
//
//   -webkit-transition: height 1s ease;
//   -moz-transition: height 1s ease;
//   -ms-transition: height 1s ease;
//   -o-transition: height 1s ease;
//   transition: height 1s ease;
// `;

// export const AccordionItemButtonDiv = styled(AccordionItemButton)`
//   background-color: white;
//   border: none;
//   outline: none !important;
//
//   font-weight: 600;
//   font-size: 22px;
//   cursor: pointer;
//   color: #334061;
//   &:hover {
//     border: none;
//   }
//   &:active {
//     border: none;
//   }
// `;

// export const FontAwesomeIconSet = styled(FontAwesomeIcon)`
//   margin-right: 2rem;
// `;

// export const AccordionItemPanelDiv = styled(AccordionItemPanel)`
//   font-size: 18px;
//   margin: 2rem 3rem;
//
//   line-height: 1.8;
//   text-align: justify;
//   text-justify: inter-word;
//   overflow: hidden;
//   a {
//     color: #4977f9;
//     text-decoration: none;
//     &:hover {
//       border-bottom: 1px dotted;
//     }
//   }
// `;

export const TopHeader = styled(Header)`
  background: linear-gradient(90.21deg, #2d54c2 8.95%, #07a5bb 94.8%);
  padding: 0rem 3rem;

  font-size: 20px;
  height: 1rem;
  font-weight: bolder;
  text-align: center;
`;

export const ThanksHeading = styled.h4`
  font-weight: 600;
  font-size: 32px;
  line-height: 0;
  color: #0f1b38;
`;

export const MainHeader = styled.div`
  background-color: transparent;
  ${media.largeMobile.max(`
    // height: 12rem !important;
    border-radius: 25px;
    width: 42rem !important;
    margin: 3rem 2.5rem !important;
  `)};
  ${media.tablet.max(`
    // height: 11rem;
    border-radius: 25px;
    margin: 2rem 5rem;
    width: 60rem;
  `)};
`;

export const MenuBar = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.6rem 4rem;

  ${media.tablet.max(`
        padding-left: 0;
         padding-right: 0;
    `)};
`;

export const Line = styled.hr`
  margin: 3rem 0rem;
  border-color: #efefef;
`;

export const Box = styled.div`
  display: flex;
  margin: 0rem 1rem;
  flex-flow: column wrap;
  position: relative;
  background: rgba(255, 255, 255, 0.04);
  border-radius: 24px;
  flex: 1 1 0%;
  align-self: auto;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px;
  max-width: initial;
  font-size: inherit;
  text-align: inherit;
  filter: none;
  width: 255px;
  height: 170px;
  overflow: hidden;
  border: 0px solid rgba(225, 225, 225, 0.3);
  ${media.largeMobile.max(`

  width: 173px;
  height: 140px;
`)};
`;

export const NavDiv = styled.div``;

export const TopSection = styled.div``;

export const FaqDiv = styled.div`
  width: auto;
  display: flex;
  background: url(${faq});
  margin: 5rem 13rem 12rem 13rem;
  background-repeat: no-repeat;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${media.largeMobile.max(`
  height: 12rem !important;
  border-radius: 25px;
    width: 42rem !important;
    margin: 3rem 2.5rem !important;
`)};
  ${media.tablet.max(`
height: 11rem;
border-radius: 25px;
margin: 2rem 5rem;
width: max-content;
`)};
`;
export const FaqImg = styled.img`
  width: 5rem;
  height: 5rem;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 3.5rem 5rem 3.5rem 8rem;
  ${media.largeMobile.max(`
  margin: 3.5rem 5rem;
`)};
`;
export const Zokyo = styled.img`
  width: 3vw;
  height: auto;
  margin-right: 0.6rem;
  ${media.tablet.max(`
      width: 6vw;
    height: auto;
    margin-top: -1rem;
    `)};
`;

export const Argo = styled.img`
  width: 1.8vw;
  height: auto;
  margin-right: 0.4rem;
  margin-right: 0.6rem;
  ${media.tablet.max(`
    width: auto;
    height: 2vh;
    margin-top: 0.2rem;
    `)};
`;
export const Zepplin = styled.img`
  height: auto;
  margin-right: 0.4rem;
  margin-left: 0.4rem;
  ${media.tablet.max(`
    width: auto;
    height: 1.5vh;
    margin-top: 0.2rem;
    `)};
`;
export const BoxDiv = styled.div`
  margin: 20px auto  !important;
  overflow: initial;
  background: transparent;
  display: flex;
  ${media.tablet.max(`
  margin-left: 18rem !important;
    `)}
  ${media.largeMobile.max(`
  margin-left: 21rem  !important;
  margin-right: 0rem;
    `)}
  //    ${media.mobile.max(`
  //  width: 299px;
  //   height: 289px;
  //   gap: 2px;
  //   grid-template-columns: repeat(auto-fill,minmax(133px,1fr));
  //   `)}
`;

export const CardDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
  gap: 200px;
  padding: 4rem 9rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  ${media.tablet.max(`
   grid-template-columns: repeat(auto-fill,minmax(373px,1fr));
    gap: 38px;
    padding: 2rem 2rem;
    `)}
  ${media.largeMobile.max(`
 grid-template-columns: repeat(auto-fill,minmax(323px,1fr));
    gap: 21px;
    padding: 2rem 2rem;
    `)}
     ${media.mobile.max(`
 grid-template-columns: repeat(auto-fill,minmax(265px,1fr));
    gap: 22px;
    padding: 1rem 1rem;
    `)}
`;

export const PoolsContainer = styled.div`
  width: auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

export const HeadingDiv = styled.div`
  display: flex;
  flex-flow: column wrap;
  position: relative;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px;
  min-width: auto;
  max-width: initial;
  filter: none;
  width: auto;
  height: auto;
  border: none;
  overflow: initial;
  flex: 0 1 0%;
  align-self: stretch;
  color: rgb(255, 255, 255);
  top: 0px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-bottom: 0 px solid rgba(225, 225, 225, 0.3);
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* or 200% */

  font-feature-settings: "salt" on, "liga" off;

  color: #a6a0bb;
  /* identical to box height, or 125% */

  text-align: center;
  text-transform: capitalize;

  /* Color / White */

  backdrop-filter: blur(4px);
  text-align: center;
  padding: 14px 5px;
  right: 0px;
  left: 0px;
  ${media.tablet.max(`
        font-size: 13px;  
       `)}
  ${media.largeMobile.max(`
        font-size: 8px; 
        padding: 0px 5px; 
       `)}
`;

export const NumberDiv = styled.div`
  display: flex;
  flex-flow: column wrap;
  position: relative;
  background: transparent;
  flex: 1 1 0%;
  align-self: auto;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0px;
  min-width: auto;
  max-width: initial;
  font-size: inherit;
  text-align: inherit;
  filter: none;
  width: auto;
  height: auto;
  border: none;
  border-radius: 0px;
  overflow: initial;
  padding: 20px;
`;
export const NumberSpan = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 37px;
  /* identical to box height, or 132% */
  font-family: Poppins !important;
  text-align: center;

  /* Color / White */

  color: #ffffff;
  ${media.tablet.max(`
        font-size: 13px;
      
    `)};
  ${media.largeMobile.max(`

   font-size: 8px;
   line-height: 16px;
  `)};
`;
export const WalletAddressContainer = styled.span`
  background: #1f1f3e;
  align-items: center;
  border-radius: 100px;
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-weight: 100;
  font-family: "Chillax-Medium" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 7rem;
  height: 2.5rem;
  padding: 0.4rem 1.3rem;
  margin-left: -2rem;
  cursor: pointer;
  display: flex;
  ${media.largeMobile.max(`
font-size: 9px;
height: 2rem;
margin-right: 0.5rem;
padding: 0.4rem 0.8rem;
    `)}
`;
export const DmodImage = styled.img`
  height: auto;
  padding: 0.25rem;
  width: 22px;
`;
export const DmodSpan = styled.div`
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  margin-top: 0.2rem;
`;
export const DmodContainer = styled.button`
  margin-right: 1rem;
  background: rgba(106, 121, 159, 0.18);
  align-items: center;
  border-radius: 100px;
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 13px !important;
  line-height: 14px;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 6rem;
  height: 2.5rem;
  padding: 1px 16px 1px 8px;
  margin-left: 0rem;
  cursor: pointer;
  display: flex;
  ${media.tablet.max(`
display: none;
margin-right: 3rem
    `)}
  ${media.largeMobile.max(`
display: none;
    `)}
    &:hover {
    opacity: 0.8;
  }
`;
export const SubNumberSpan = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 140% */

  text-align: center;
  text-transform: capitalize;

  color: #ff9a36;

  align-self: flex-end;
  margin-right: 7px;
  margin-bottom: 3px;
`;
export const LightSpan = styled.span`
  flex: initial;
  align-self: auto;
  background: transparent;
  margin: 0px;
  padding: 0px;
  text-align: initial;
  inset: auto 10px 5px auto;
  position: absolute;
  font-weight: 600;
  font-size: 9px;
  margin-right: 7px;
  margin-bottom: 3px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #19d2ff;
  z-index: -1;
  ${media.largeMobile.max(`
font-size: 7px;
`)};
`;
export const FooterMain = styled.div`
  text-align: left;
  padding: 0rem 2rem;
  margin-left: 3rem;
  padding-right: 8rem;

  ${media.tablet.max(`
            margin-left: 0rem;
    `)};
  ${media.largeMobile.max(`
            margin-left: -1rem;
            padding: 0rem;
    `)};
  ${media.mobile.max(`
            margin-left: -1rem;
            padding: 0rem;
    `)};
`;
export const PoolActionContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 1rem auto;
  width: 100%;
`;
export const WalletDiv = styled.button`
  border: 0px;

  background: rgba(106, 121, 159, 0.18);
  border-radius: 100px;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 600;
  font-size: 12.5px;
  color: white;
  padding: 0.5rem 2.5rem 0.5rem 1.2rem;
  height: 2.5rem;
  cursor: pointer;
  ${media.tablet.max(`
  font-size: 10px;
 display: none;
      `)}
`;
export const WalletSection = styled.div`
  display: flex;
  ${media.tablet.max(`
      margin-right: 0rem;
    `)}
`;

export const StakeButton = styled.button`
  margin-right: ${(props) => (props.withdraw ? "5px;" : "")};
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 100px;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;

  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 44px;
  padding: 0px 24px;
  width: 100%;
  &:hover {
    opacity: ${(props) => (props.withdraw ? "0.7;" : "")};
    background: ${(props) =>
      props.withdraw
        ? ""
        : "linear-gradient(342deg,#5902d2,rgba(133,66,244,0) 53.86%),radial-gradient(58.26% 110.26% at 0 103.31%,#ff39f0 26.56%,rgba(16,231,251,0) 110%),radial-gradient(67.56% 110.56% at 100% 0,#ffccfb 0,#19d2ff 45.31%,rgba(238,65,255,0) 110%),radial-gradient(83.68% 83.68% at 0 0,#5902d2 0,rgba(133,66,244,0) 150%);"};
  }
  background: ${(props) =>
    props.withdraw
      ? "rgba(255, 255, 255, 0.03);;"
      : "linear-gradient(342deg,#5902d2,rgba(133,66,244,0) 53.86%),radial-gradient(58.26% 110.26% at 0 103.31%,#19d2ff 26.56%,rgba(16,231,251,0) 110%),radial-gradient(67.56% 110.56% at 100% 0,#ffccfb 0,#ff39f0 45.31%,rgba(238,65,255,0) 110%),radial-gradient(83.68% 83.68% at 0 0,#5902d2 0,rgba(133,66,244,0) 150%);"};
  color: white;
  min-width: 13rem;
  ${media.tablet.max(`
         min-width: 8rem;
    `)};
  ${media.largeMobile.max(`
         min-width: 6.7rem;
         font-size: 13px;
         height: 41px;
    `)};
  ${media.mobile.max(`
         min-width: 6rem;
         font-size: 11px;
         height: 41px;
    `)};
`;
export const ModalButton = styled.button`
  height: 44px;
  padding: 0px 24px;
  min-width: 14rem;
  cursor: pointer;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background: linear-gradient(342deg,#5902d2,rgba(133,66,244,0) 53.86%),radial-gradient(58.26% 110.26% at 0 103.31%,#19d2ff 26.56%,rgba(16,231,251,0) 110%),radial-gradient(67.56% 110.56% at 100% 0,#ffccfb 0,#ff39f0 45.31%,rgba(238,65,255,0) 110%),radial-gradient(83.68% 83.68% at 0 0,#5902d2 0,rgba(133,66,244,0) 150%);
  border-radius: 100px;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  border: 0px solid transparent;
  text-align: center;
  text-transform: capitalize;
color: white;
  &:hover {
    "linear-gradient(342deg,#5902d2,rgba(133,66,244,0) 53.86%),radial-gradient(58.26% 110.26% at 0 103.31%,#ff39f0 26.56%,rgba(16,231,251,0) 110%),radial-gradient(67.56% 110.56% at 100% 0,#ffccfb 0,#19d2ff 45.31%,rgba(238,65,255,0) 110%),radial-gradient(83.68% 83.68% at 0 0,#5902d2 0,rgba(133,66,244,0) 150%);
  }
  /* Color / White */

  color: #ffffff;
`;
export const MetamaskButton = styled.button`
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 44px;
  padding: 0px 24px;
  background: linear-gradient(342deg, #5902d2, rgba(133, 66, 244, 0) 53.86%),
    radial-gradient(
      58.26% 110.26% at 0 103.31%,
      #19d2ff 26.56%,
      rgba(16, 231, 251, 0) 110%
    ),
    radial-gradient(
      67.56% 110.56% at 100% 0,
      #ffccfb 0,
      #ff39f0 45.31%,
      rgba(238, 65, 255, 0) 110%
    ),
    radial-gradient(83.68% 83.68% at 0 0, #5902d2 0, rgba(133, 66, 244, 0) 150%);
  border-radius: 100px;
  color: ${(props) => (props.withdraw ? "#7151e1" : "white")};
  border: ${(props) => (props.withdraw ? "1px solid #7151e1" : "null")};
  min-width: 8rem;
  margin: 2rem 0rem;
`;
export const MainCard = styled.div`
  display: flex;
  flex-direction: column;
  align-self: baseline;
  color: rgb(118, 69, 217);
  // box-shadow: rgba(25, 19, 38, 0.15) 8px 7px 8px;
  padding: 2rem 2rem 1rem;
  animation: 3s ease 0s infinite normal none running idZEjz;
  background: #0f0d27;
  border-radius: 8px;
  border: 0px solid rgba(225, 225, 225, 0.3);
  position: relative;
`;
export const LastDiv = styled.div``;

export const Divider = styled.hr`
  margin: 0;
  border: 0;
  background: #c4c4c4;
  border-radius: 24px;
  width: 100%;
  height: 0.1px;
`;
export const TopDiv = styled.div`
  border-radius: 24px 24px 0px 0px;
`;
export const AprButton = styled.button`
  margin-top: 1.4rem;
  min-width: 12rem;
  height: 2.5rem;
  border-color: white;
  padding: 0.5rem 0.5em;
  color: #7151e1;
  background: linear-gradient(318.86deg, #010013 0%, rgba(1, 0, 19, 0.1) 53.86%),
    radial-gradient(
      83.68% 83.68% at 0% 0%,
      #010013 0%,
      rgba(1, 0, 19, 0.2) 95.83%
    );
  border-radius: 37px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  text-align: center;
  text-transform: capitalize;

  color: #ceb1ff;
`;
export const Epoch = styled.h6`
  margin: 0;
  float: right;

  font-family: Poppins !important;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 24px;
/* identical to box height, or 185% */

font-feature-settings: 'pnum' on, 'lnum' on;

/* Gray/8 */

color: #A6A0BB;
  ${media.tablet.max(`
    font-size: 15px;
    `)}
  ${media.largeMobile.max(`
    font-size: 12px;
    `)}
    ${media.mobile.max(`
    font-size: 9px;
    `)}
`;
export const BottomNumber = styled.span`
  float: right;
  font-family: Poppins !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 185% */
  
  font-feature-settings: 'pnum' on, 'lnum' on;
  
  /* White */
  
  color: #FFFFFF;  ${media.tablet.max(`
    font-size: 15px;
    `)}
  ${media.largeMobile.max(`
    font-size: 12.5px;
    margin-top: 0.2rem;
    `)}
     ${media.mobile.max(`
    font-size: 8.5px;
    margin-top: 0.4rem;
    `)}
`;
export const BottomText = styled.span`
font-family: Poppins !important;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 24px;
/* identical to box height, or 185% */

font-feature-settings: 'pnum' on, 'lnum' on;

/* Gray/8 */

color: #A6A0BB;
  ${media.tablet.max(`
    font-size: 14px;
    `)}
  ${media.largeMobile.max(`
    font-size: 11px;
    `)}
     ${media.mobile.max(`
    font-size: 10px;
    `)}
`;
export const StakeText = styled.h4`
  font-family: Poppins !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 185% */

  font-feature-settings: "pnum" on, "lnum" on;

  /* Gray/8 */

  color: #a6a0bb;

  margin-bottom: 0;
  ${media.tablet.max(`
    font-size: 11px;
    `)}
`;
export const LastDivTwo = styled.div`
  background: #171532;
  border-radius: 16px;
  padding: 1rem;
`;
export const EarnedText = styled.h4`
  margin-bottom: 0;

  font-family: Poppins !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 185% */

  font-feature-settings: "pnum" on, "lnum" on;

  /* Gray/8 */

  color: #a6a0bb;

  margin-top: 0;
  ${media.tablet.max(`
    font-size: 11px;
    `)}
`;
export const EarnedTextTwo = styled.h4`
  margin-bottom: 0;

  font-family: Poppins !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 185% */

  font-feature-settings: "pnum" on, "lnum" on;

  /* Gray/8 */

  background: linear-gradient(
    73.6deg,
    #85ffc4 2.11%,
    #5cc6ff 42.39%,
    #bc85ff 85.72%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 0;
  ${media.tablet.max(`
    font-size: 11px;
    `)}
`;
export const CardHeading = styled.h2`
font-family: Poppins !immportant;
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 28px;
/* identical to box height, or 117% */

font-feature-settings: 'pnum' on, 'lnum' on;

/* White */

color: #FFFFFF;
  margin-left: 1rem;
  margin-bottom: 0;
  margin-top: 0.2rem;
  ${media.tablet.max(`
    font-size: 28px;
    `)}
  ${media.largeMobile.max(`
    font-size: 23px;
    `)}
     ${media.mobile.max(`
    font-size: 18px;
    `)}
`;
export const EarnedNumber = styled.h2`
  margin: 0rem 0rem;
  font-family: Poppins !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 185% */

  font-feature-settings: "pnum" on, "lnum" on;

  /* White */

  color: #ffffff;
  ${media.tablet.max(`
    font-size: 12px;
    `)}
`;
export const EarnedNumberTwo = styled.h2`
  margin: 0rem 0rem;
  font-family: Poppins !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 185% */

  font-feature-settings: "pnum" on, "lnum" on;

  /* White */
  color: #b2b0ff;
  ${media.tablet.max(`
    font-size: 12px;
    `)}
`;
export const MaxNumber = styled.button`
  height: 28px;
  background-color: rgba(21, 61, 111, 0.44);
  border: 1px solid rgba(21, 61, 111, 0.44);
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  margin-right: 0.5rem;
  color: rgb(109, 168, 255);
  &:hover {
    border: 1px solid rgb(33, 114, 229);
  }
`;
export const Row = styled.div`
  display: flex;
`;
export const SectionRow = styled.div`
  display: grid;
  margin-top: 0rem;
  justify-content: center;
  ${media.largeMobile.max(`display: grid !important;
  margin-top: 0  !important;
    padding-left: 0.3rem  !important;
    padding-right: 0.3rem  !important;
    `)};
  ${media.mobile.max(`display: grid !important;
  margin-top: 0  !important;
    padding-left: 0.3rem  !important;
    padding-right: 0.3rem  !important;
    `)};
  ${media.tablet.max(`
   margin-top: 0;
  
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    margin-top: 4rem;
    
    `)};
`;
export const Col = styled.div``;
export const BodyCol = styled.div`
  ${media.largeMobile.max(`display: flex;
   margin-right: -2rem;
    `)};
  ${media.mobile.max(`display: flex;
   margin-right: -2rem;
    `)};
`;
export const PoolInfoItems = styled.div`
  margin-bottom: 0.5rem;
  display: ;
`;
export const AprText = styled.p`
  background: rgba(17, 202, 190, 0.1);
  border-radius: 36px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #11cabe;
  margin-left: 1.5rem;
  padding: 0.5rem 0.8rem;
  backdrop-filter: blur(4px);
  margin-right: 1rem;
  ${media.tablet.max(`
    font-size: 12px;
    `)}
`;

export const TokenInput = styled.div`
  height: 30px;
  margin-top: 1rem;
  border-radius: 22px;
  padding: 0px;
  background: transparent;
  color: white;
  border: 1px solid rgb(59, 60, 78);
  display: flex;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  align-items: center;
  padding: 0.75rem 0.5rem 0.75rem 1rem;
  &:active {
    background: linear-gradient(rgb(7, 6, 24), rgb(7, 6, 24)) padding-box
        padding-box,
      linear-gradient(
          73.6deg,
          rgb(133, 255, 196) 2.11%,
          rgb(92, 198, 255) 42.39%,
          rgb(188, 133, 255) 85.72%
        )
        border-box border-box;
    border-radius: 22px;
    outline: 0px;
    outline-offset: 0px;
    box-shadow: transparent 0px 0px 0px 0.2rem !important;
    border: 2px solid transparent !important;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem transparent !important;
    background: linear-gradient(#070618, #070618) padding-box,
      /*this is your grey background*/
        linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%)
        border-box;
    border: 2px solid transparent !important;
    border-radius: 14px;
    outline: 0;
    outline-offset: 0;
  }
  &:focus-visible {
    background: linear-gradient(#070618, #070618) padding-box,
      /*this is your grey background*/
        linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%)
        border-box;
    border: 2px solid transparent;
    border-radius: 14px;
    outline: 0;
  }
  &:focus-within {
    background: linear-gradient(rgb(7, 6, 24), rgb(7, 6, 24)) padding-box
        padding-box,
      linear-gradient(
          73.6deg,
          rgb(133, 255, 196) 2.11%,
          rgb(92, 198, 255) 42.39%,
          rgb(188, 133, 255) 85.72%
        )
        border-box border-box;
    border: 2px solid transparent;
    border-radius: 22px;
    outline: 0px;
  }
`;

export const Input = styled.input`
  color: rgb(255, 255, 255);
  width: 0px;
  position: relative;
  font-weight: 500;
  outline: currentcolor none medium;
  border: medium none;
  flex: 1 1 auto;
  background-color: transparent;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  appearance: textfield;
  // ::placeholder {
  //   font-size: 18px;
  // }
`;

export const VectorImg = styled.img`
  height: 24rem;
  width: auto;
  ${media.tablet.max(`
       height: 15rem;
    `)};
  ${media.largeMobile.max(`
        display: none;
    `)};
  ${media.mobile.max(`
        display: none;
    `)};
`;
export const UnionImg = styled.img`
  margin-top: -3rem;
  ${media.tablet.max(`
        width: 26rem;
    `)};
  ${media.largeMobile.max(`
        width: 24rem;
    `)};
  ${media.mobile.max(`
        width: 21rem;
    `)};
`;
export const LearnImg = styled.img`
  margin-top: 2rem;
  margin-left: 5rem;
  &:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }
  ${media.tablet.max(`
        margin-top: 1rem;
        margin-left: 3rem;
    `)};
`;
export const FaqTitle = styled.h4`
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 130%;
  /* identical to box height, or 39px */
  margin-top: 0px;
  letter-spacing: -0.02em;

  color: #ffffff;
  margin-bottom: 0;
  ${media.tablet.max(`
          font-size: 20px;
          margin-left: 0rem;
    `)};
  ${media.largeMobile.max(`
          font-size: 17px;
            margin-left: 0rem;
    `)};
  ${media.mobile.max(`
        font-size: 14px;
    margin-left: 2.5rem;
    `)};
`;
export const FaqSubTitle = styled.h5`
  font-family: Poppins !important;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  /* or 185% */
  margin-top: 5px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  color: #a6a0bb;

  ${media.tablet.max(`
          font-size: 13px;
            margin-left: 0rem;
            margin-bottom: 0;
            width: 30rem;
    `)};

  ${media.mobile.max(`
        font-size: 11px;
    margin-left: 0rem;
    margin-bottom: 0;

    `)};
`;
export const LearnLink = styled.a`
  background: linear-gradient(132.56deg, #5799eb 5.63%, #9f74fb 106.18%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
`;
export const ArrowRightIcon = styled(ArrowRight)`
  background: linear-gradient(132.56deg, #5799eb 5.63%, #9f74fb 106.18%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const ImgDiv = styled.div`
  background: linear-gradient(
      318.86deg,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 53.86%
    ),
    radial-gradient(
      58.26% 58.26% at 0% 103.31%,
      #19d2ff 26.56%,
      rgba(16, 231, 251, 0) 100%
    ),
    radial-gradient(
      67.56% 67.56% at 100% 0%,
      #ffccfb 0%,
      #ff39f0 45.31%,
      rgba(238, 65, 255, 0) 100%
    ),
    radial-gradient(
      83.68% 83.68% at 0% 0%,
      #5902d2 0%,
      rgba(133, 66, 244, 0) 95.83%
    );
  filter: blur(79px);
  transform: rotate(-176.18deg);
  width: 15rem;
  height: 10rem;
`;

export const ApproveImg = styled.img`
  position: absolute;
  top: calc(50% - 6rem);
  left: calc(50% - 5.5rem);
  width: 10rem;
  margin-top: ${(props) => (props.failed ? "-6rem" : "0rem")};
`;

export const PendingImg = styled.img`
  top: calc(50% - 6rem);
  left: calc(50% - 5.5rem);
  width: 9rem;
  height: 8rem;
`;

export const FailedPara = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 29px;
  /* or 161% */

  text-align: center;

  /* Pink */

  color: #ff39f0;
  width: 20rem;
`;

export const CardTopRow = styled.div`
  position: absolute;
  top: 0;
  left: 22px;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  height: 7rem;
  padding: 1rem;
`;

export const NavImg = styled.img``;
export const FooterDiv = styled.div`
  background-color: transparent;
  height: 3rem;
  color: white;
  display: flex;
  margin-left: 2rem;
  margin-right: 2rem;
  border-top: 1px solid;
  padding-top: 0.2rem;
  border-image-source: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    #ffffff 50%,
    rgba(255, 255, 255, 0.08) 100%
  );
  border-image-slice: 1;
  ${media.tablet.max(`
  width: 130vw;
 
`)};
  ${media.largeMobile.max(`
  width: 145vw !important;
  margin-left: 6.5rem;
  margin-right: 0rem;
`)};
`;

export const FooterIcon = styled.div`
  color: #999999;
  font-size: 16px;
  display: flex;
  cursor: pointer;
  margin-left: 1rem;
  background: rgba(48, 41, 72, 0.46);
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  &:hover {
    color: #fff;
  }
`;

export const FooterRow = styled.div`
  display: flex;
`;
export const FooterFirstRow = styled.div`
  display: flex;
`;
export const FooterText = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  border-right: 0.5px solid rgba(166, 160, 187, 0.4);
  padding-right: 0.8rem;
  color: #878dad;
  font-family: "Chillax-Medium" !important;
  height: 1.2rem;
  ${media.largeMobile.max(`
  font-size: 8px;
`)};
`;
export const FooterTextTwo = styled.a`
  font-style: normal;
  margin-left: 1rem;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #878dad;
  font-family: "Chillax-Medium" !important;
  cursor: pointer;
  text-decoration: none;
  ${media.largeMobile.max(`
  font-size: 8px;
  margin-left: 0.5rem;
`)};
  &:hover {
    color: #7d8fd0;
  }
`;
export const FooterLogo = styled.img`
  width: 16px;
  height: 16px;
  ${media.mobile.max(`
  width: 16px;
  height: 16px;
`)};
`;
export const FooterFontIcon = styled(FontAwesomeIcon)`
  margin: 0.43rem;
  color: #696694;
`;
export const IconImg = styled.img``;
export const TableDiv = styled.div`
  background: #0c0b1f;
  border-radius: 12px;
  padding: 2rem;
  margin: 2rem 13rem;
  height: fit-content;
  // overflow-y: scroll;
  animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1.8s;
  animate-delay: 0.5s;
  ${media.tablet.max(`
  width: max-content;
  margin: 2rem 5rem;  `)}
  ${media.largeMobile.max(`
display: none;  `)}
`;
export const TableCardDiv = styled.div`
  display: none;
  ${media.largeMobile.max(`
display: initial;  `)}
`;
export const FirstTableDataHeading = styled.span`
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* or 100% */
  display: flex;
  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on;
  /* White */
  color: #ffffff;
`;
export const TableCardRow = styled.div`
  display: flex;
`;
export const TableCard = styled.div`
  background: #0e0d20;
  border-radius: 12px;
  margin: 2rem 10rem;
  width: fit-content;
  padding: 2rem 3rem;
`;
export const Table = styled.table`
  animation: fadeInUp; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2.2s;
`;
export const TableHeading = styled.th`
  font-family: Poppins !important;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 185% */
  color: #a6a0bb;
  text-align: left;
  ${media.tablet.max(`
  font-size: 9px;
  padding-left: 1rem;  `)}
  ${media.mobile.max(`
  font-size: 9px;
  padding-left: 1rem; `)}
`;
export const TableRow = styled.tr`
  // cursor: pointer;
`;

export const FirstTableData = styled.td`
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* or 100% */
  display: flex;
  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on;
  padding-top: 2rem;
  /* White */
  padding-right: 3rem;
  color: #ffffff;
  ${media.tablet.max(`
padding-right: 2rem;  `)}
`;

export const LogoDiv = styled.div`
  width: 32px;
  height: 32px;
  background: #211e40;
  border-radius: 100%;
  overflow: hidden;
  padding: 4px;
`;

export const TableLogo = styled.img`
  object-fit: cover;
  width: auto;
  height: 1.2rem;
  margin: 0.4rem;
  opacity: 1 !important;
`;
export const NameSpan = styled.div`
  margin-left: 0.5rem;
  ${media.mobile.max(`
  font-size: 9px;
  `)}
`;

export const TableData = styled.td`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 171% */
  padding-top: 1rem;
  color: #ffffff;
  padding-right: 4rem;
  ${media.tablet.max(`
  font-size: 9px;
  padding-left: 1rem;
  padding-right: 2rem; `)}
`;

export const TableButtonData = styled.td`
  display: flex;
  justifycontent: flex-end;
  padding-right: 0rem;
  position: relative;
  right: -3rem;
  top: -1rem;
  ${media.tablet.max(`
  right: -1rem;  `)}
`;

export const PoolRowTable = styled(TableRow)`
  opacity: 1;
`;

export const DetailsButton = styled.button`
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 100px;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;

  font-family: Poppins !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  font-feature-settings: "pnum" on, "lnum" on;

  color: #9e8be0;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;

  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 44px;
  padding: 0px 24px;
  background: #1e1d35;
  min-width: 9rem;
  &:hover {
    opacity: 0.8;
  }
`;

export const TableStakeButton = styled.button`
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 100px;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;

  font-family: Poppins !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  font-feature-settings: "pnum" on, "lnum" on;

  color: #9e8be0;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;

  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 44px;
  padding: 0px 24px;
  background: #1e1d35;
  min-width: 9rem;
  margin-left: 1rem;
  &:hover {
    opacity: 0.8;
  }
`;

export const HarvestButton = styled.button`
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  border-radius: 100px;
  box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
  cursor: pointer;
  display: inline-flex;
  width: 100%;
  font-family: Poppins !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  font-feature-settings: "pnum" on, "lnum" on;

  color: #9e8be0;
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 0.03em;

  outline: 0px;
  transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
  height: 44px;
  padding: 0px 24px;
  background: #1e1d35;
  min-width: 9rem;
  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonText = styled.span`
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  background: linear-gradient(
    73.6deg,
    #85ffc4 2.11%,
    #5cc6ff 42.39%,
    #bc85ff 85.72%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const TimeSubHeading = styled.span`
  font-family: Poppins !IMPORTANT;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;
  justify-content: center;
  display: flex;
  color: #00c5ff;
  width: fit-content;
  text-align: center;
  background: rgba(255, 255, 255, 0.12);
  /* opacity: 8.5; */
  border-radius: 64px;
  padding: 1rem;
  ${media.largeMobile.max(`
  margin-left: 15rem !important;
    font-size: 18px;
    width: fit-content;`)}
  ${media.tablet.max(`
  margin-left: 10rem;`)}
`;
export const TimeHeading = styled.span`
  font-family: Poppins !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 185% */
  color: #a6a0bb;
  justify-content: center;
  display: flex;
  ${media.largeMobile.max(`
  margin-left: 15.5rem !important; `)}
  ${media.tablet.max(`
  margin-left: 10rem;`)}
`;
export const ConnectButtonText = styled.h4`
  font-family: Poppins !important;
  font-style: normal;
  font-weight: bold;
  font-size: 12.5px;
  margin-right: 0.25rem;
  background: linear-gradient(
    73.6deg,
    #91dcf0 6.19%,
    #25b0d7 25.05%,
    #fa8ffa 57.68%
  );
  ${media.largeMobile.max(`
font-size: 8px;      `)}
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const NavIconButton = styled.button`
  margin-left: 1rem;
  height: 38px;
  width: 38px;
  background-color: rgba(106, 121, 159, 0.18);
  border-radius: 100%;
  cursor: pointer;
  // padding: 0.5rem 0.7rem;
  padding: 0;
  border: none;
  background-color: rgba(106, 121, 159, 0.18);
  &:hover {
    opacity: 0.8;
  }
  // &:active {
  //   background: linear-gradient(#070618, #070618) padding-box,
  //     linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%)
  //       border-box;
  //   border: 1px solid transparent;
  //   outline: 0;
  // }
  // &:hover {
  //   background: linear-gradient(#070618, #070618) padding-box,
  //     linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%)
  //       border-box;
  //   outline: 0;
  //   border: 1px solid transparent;
  // }
  // &:focus-within {
  //   background: linear-gradient(#070618, #070618) padding-box,
  //     linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%)
  //       border-box;
  //   outline: 0;
  //   border: 1px solid transparent;
  // }
  ${media.tablet.max(`
    `)}
  ${media.largeMobile.max(`
    // padding: 0.4rem 0.6rem;
    padding: 0.4rem 0.5rem 0.5rem 0.5rem;
    width: 32px;
    margin-left: 0px;
    height: 32px;
      `)}
`;
export const MenuFlyout = styled.span`
  min-width: 8.125rem;
  background-color: #0d0c1e;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 6rem;
  right: 4rem;
  z-index: 100;
  ${media.largeMobile.max(`
  top: 8rem;
    right: -21rem;`)}
  ${media.tablet.max(`
  display: none;
`)}
`;

export const MenuFlyoutTablet = styled.span`
  display: none;
  ${media.largeMobile.max(`
  min-width: 8.125rem;
  background-color: #0d0c1e;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 5rem;
  right: 2rem;
  z-index: 100;
  top: 8rem;
    right: -21rem !important;
`)}
  ${media.tablet.max(`
  min-width: 8.125rem;
  background-color: #0d0c1e;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 5rem;
  right: 2rem;
  z-index: 100;
  top: 8rem;
    right: -17rem;
`)}
`;

export const MenuItemLink = styled.a`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem;
  color: #c3c5cb;
  text-decoration: none;
  :hover {
    color: rgb(255, 255, 255);
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`;
export const TabletConnectButtonText = styled.h4`
  font-family: Poppins !important;
  font-style: normal;
  font-weight: bold;
  font-size: 12.5px;
  margin-right: 0.25rem;
  background: linear-gradient(
    73.6deg,
    #91dcf0 6.19%,
    #25b0d7 25.05%,
    #fa8ffa 57.68%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const TabletMenuDiv = styled.div`
  color: white;
  font-size: 13px;
  font-family: "Poppins" !important;
  font-weight: 600;
`;
export const DmodImgTablet = styled.img`
  width: 1rem;
  height: 1.2rem;
  margin-top: 0.5rem;
  margin-right: 0.4rem;
`;

export const ModalContent = styled.div`
  background-color: rgb(17, 16, 29);
  box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px 0px;
  padding: 0.5rem 1rem;
  width: fit-content;
  overflow: hidden;
  align-self: center;
  max-height: 80vh;
  min-height: auto;
  display: flex;
  border-radius: 12px;
  ${media.largeMobile.max(`
  min-width: 80%;
  padding: 0.8rem;
  &.p-dialog .p-dialog-header { 
    padding: 0rem 0.5rem 1.5rem 0.5rem;
  }
  &.p-dialog .p-dialog-content { 
    padding: 0rem;
  }
  `)}
`;

export const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`;

export const InfoCard = styled.div`
  padding: 1rem;
  border: 1px solid #40444f;
  border-radius: 20px;
  position: relative;
  display: grid;
  grid-row-gap: 12px;
  margin-bottom: 20px;
`;

export const AccountGroupingRow = styled.div`
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: rgb(255, 255, 255);
  display: flex;
  div {
    align-items: center;
  }
`;

export const AccountSection = styled.div`
  background-color: transparent;
  // padding: 0rem 1rem;
  ${media.tablet.max(`
  padding: 0rem 1rem 0rem 1rem;
  `)};
`;

export const YourAccount = styled.div`
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 400;
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }
`;

export const LowerSection = styled.div`
  padding: 1.5rem;
  flex-grow: 1;
  overflow: auto;
  background-color: transparent;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  h5 {
    margin: 0;
    font-weight: 400;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-weight: 400;
    font-size: 16px;
    color: rgb(255, 255, 255);
    font-family: "Inter var", sans-serif !important;
  }
`;

export const TextModalSubHeading = styled.p`
  font-size: 0.825rem;
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: rgb(108, 114, 132);
  color: #6c7284;
  text-decoration: none;
  display: flex;
`;

export const AddressLink = styled.a`
  font-size: 0.825rem;
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: rgb(108, 114, 132);
  color: #6c7284;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  :hover {
    color: #c3c5cb;
  }
`;
export const WalletName = styled.div`
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: #6c7284;
`;

export const IconWrapper = styled.div`
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  & > img,
  span {
    height: ${({ size }) => (size ? size + "px" : "32px")};
    width: ${({ size }) => (size ? size + "px" : "32px")};
  }
  ${media.tablet.max(`
  align-items: flex-end;
  `)};
`;

export const TransactionListWrapper = styled.div``;
const Base = styled(RebassButton)`
  font-weight: 500;
  text-align: center;
  border-radius: 20px;
  outline: none;
  border: 1px solid transparent;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    cursor: auto;
  }

  > * {
    user-select: none;
  }
`;
const ButtonSecondary = styled(Base)`
  background: #1c1924;
  border-radius: 100px;
  border: 0px solid hsla(0, 0%, 100%, 0.4);
  box-sizing: border-box;
  border-radius: 25px;
  font-weight: 600;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;

  text-align: center;
  text-transform: capitalize;
  color: #fff;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  min-width: 7rem;
  height: 2.5rem;
  padding: 0.4rem 1.3rem;
  cursor: pointer;
  ${media.tablet.max(`
  font-size: 10px;
  `)};
`;

export const WalletAction = styled(ButtonSecondary)`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.825rem;
  padding: 4px 6px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const AccountControl = styled.div`
  display: flex;
  justify-content: flex-start;
  min-width: 0;
  width: 100%;

  font-weight: 500;
  font-size: 1.25rem;

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const StyledSVG = styled.svg`
  animation: 2s ${rotate} linear infinite;
  height: 16px;
  width: 16px;
  path {
    stroke: #42a0bb;
  }
`;

export const LinkIcon = styled(LinkIconFeather)`
  height: 16px;
  width: 18px;
  margin-left: 10px;
  stroke: #6c7284;
`;

export const ModalHeader = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin: 0rem 0.5rem 0.5rem;
  color: white;
  font-size: 16px;
  font-weight: 500 ${media.largeMobile.max(`
    font-size: 12px;
  `)};
`;

export const CloseIcon = styled(X)`
  stroke: #6c757d;
  cursor: pointer;
  padding: 5px;
  &:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
    border-radius: 100px;
    padding: 5px;
  }
`;
export const NavSpan = styled.span`
  display: flex;
  flex-grow: 1;
  margin-left: 2rem;
  margin-top: 0.3rem;

  ${media.tablet.max(`
  display: none;
  `)}
`;

export const NavText = styled.h4`
  font-family: Chillax-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* identical to box height, or 87% */
  cursor: pointer;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  color: #a6a0bb;
  margin-left: 2rem;
  opacity: 0.9;
  // margin-top: 0.2rem;
  backdrop-filter: blur(4px);
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  line-height: 1.8rem;
  border-bottom: 1.2px solid transparent;
  &:hover {
    background: linear-gradient(
      73.6deg,
      #91dcf0 6.19%,
      #25b0d7 25.05%,
      #fa8ffa 57.68%
    );

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-bottom: 1.2px solid;
    border-image: linear-gradient(
      73.6deg,
      #91dcf0 6.19%,
      #25b0d7 25.05%,
      #fa8ffa 57.68%
    );
    border-image-slice: 1;
  }
  // &:active {
  //   background: linear-gradient(
  //     73.6deg,
  //     #91dcf0 6.19%,
  //     #25b0d7 25.05%,
  //     #fa8ffa 57.68%
  //   );
  //   animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
  //   animation-duration: 2.5s;
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: transparent;
  //   border-bottom: 1.2px solid;
  //   border-image: linear-gradient(73.6deg,#91dcf0 6.19%,#25b0d7 25.05%,#fa8ffa 57.68%);
  //   border-image-slice: 1;
  //   padding-left: 0.6rem;
  //   padding-right: 0.6rem;
  //   line-height: 1.8rem;
  // }
`;

export const ExternalImg = styled.img`
  margin-left: 0.2rem;
`;
