import { addresses } from "@project/contracts";
import { ethers } from "ethers";
import React, { useState } from "react";
import { HelpCircle } from "react-feather";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";
import {
  getLPToken,
  getStaking,
  getToken,
  getYieldFarmLP,
  getYieldFarmToken,
} from "../contracts";
import usePoolData from "../hooks/usePoolData";
import useUserPoolsData from "../hooks/useUserPoolsData";
import Apr from "../images/Apr.png";
import Dmod from "../images/DmodToken.png";
import Pancake from "../images/pancake.png";
import Star from "../images/star.png";
import Uniswap from "../images/uniswap.png";
import {
  AprText,
  BottomNumber,
  BottomText,
  Bullet,
  ButtonText,
  CardHeading,
  CardTopRow,
  EarnedNumber,
  EarnedNumberTwo,
  EarnedText,
  EarnedTextTwo,
  Epoch,
  HarvestButton,
  Input,
  LastDiv,
  LastDivTwo,
  LogoDiv,
  MainCard,
  MaxNumber,
  MetamaskButton,
  PoolActionContainer,
  PoolInfoItems,
  Row,
  StakeButton,
  StakeText,
  TableLogo,
  TokenInput,
  TopDiv,
} from "../styles";
import { numberWithCommas, tokenBNtoNumber } from "../utils";
import TransactionModal from "./Modals/TransactionModal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#221E40",
    color: "white",
    minWidth: "27rem",
    borderRadius: "16px",
    border: "0px",
  },
};

const cardModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "",
    minWidth: "27rem",
    borderRadius: "16px",
    border: "0px",
    padding: "0",
    overflow: "none",
  },
};

function PoolCard({
  web3,
  name,
  pool,
  cardSubtitle,
  cardSubtitleTwo,
  showModal,
  switchModal,
}) {
  const [
    provider,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    signer,
    address,
    network,
  ] = web3;
  const [showWithdrawInput, setShowWithdrawInput] = useState(false);
  const [showStakeInput, setShowStakeInput] = useState(false);
  const [stakeDmodAmount, setStakeDmodAmount] = useState(0);
  const [withdrawDmodAmount, setWithdrawDmodAmount] = useState(0);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [transactionData, setTransactionData] = useState({});

  let [
    currentEpochDMOD,
    rewardForCurrentEpoch,
    poolBalance,
    totalEpochDMOD,
    totalDistributedAmount,
    poolApr,
    updateDataPool,
  ] = usePoolData(pool, [
    provider,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    signer,
    address,
    network,
  ]);

  let [
    potentialUserReward,
    epochStakeNext,
    totalAccumulatedReward,
    totalAvailableReward,
    lastEpochIdHarvested,
    updateDataUser,
  ] = useUserPoolsData(pool, [
    provider,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    signer,
    address,
    network,
  ]);

  const depositDmod = async () => {
    const network = (await provider.getNetwork()).name;

    let token;

    if (stakeDmodAmount <= 0) {
      return;
    }

    const staking = await getStaking(signer.provider, signer);

    if (pool === "dmod") {
      token = await getToken(signer.provider, signer);
    }
    if (pool === "dmodlp") {
      token = await getLPToken(signer.provider, signer);
    }

    let tx, tx1;

    try {
      setIsOpen(true);
      setTransactionData({
        type: "Approve",
        status: "waiting for approval on metamask",
      });
      const approvedAmt = await token.allowance(
        address,
        addresses[network].staking
      );

      const isApproved = ethers.utils
        .parseEther(stakeDmodAmount)
        .lte(approvedAmt);

      if (!isApproved) {
        tx = await token.approve(
          addresses[network].staking,
          ethers.utils.parseEther(stakeDmodAmount)
        );

        setTransactionData({
          type: "Approve",
          status: "pending",
          tx: tx,
        });

        await tx.wait();
        setTransactionData({
          type: "Approve",
          status: "success",
          tx: tx,
        });
      }
      try {
        tx1 = await staking.deposit(
          token.address,
          ethers.utils.parseEther(stakeDmodAmount)
        );

        setTransactionData({
          type: "Deposit",
          status: "pending",
          tx: tx1,
        });
        await tx1.wait();
        setTransactionData({
          type: "Deposit",
          status: "success",
          tx: tx1,
        });
        updateDataPool();
        updateDataUser();
        setShowStakeInput(!showStakeInput);
      } catch (error) {
        console.log(error);
        setTransactionData({
          type: "Deposit",
          status: "failed",
        });
        setShowStakeInput(!showStakeInput);
      }
    } catch (error) {
      console.log(error);
      setTransactionData({
        type: "Approve",
        status: "failed",
      });
      setShowStakeInput(!showStakeInput);
    }
  };

  const withdrawDmod = async () => {
    if (withdrawDmodAmount <= 0) {
      return;
    }

    const staking = await getStaking(signer.provider, signer);

    if (pool === "dmod") {
      try {
        setIsOpen(true);
        setTransactionData({
          type: "Withdraw",
          status: "waiting for approval on metamask",
        });
        const tx1 = await staking.withdraw(
          addresses[network].dmodToken,
          ethers.utils.parseEther(withdrawDmodAmount)
        );

        setTransactionData({
          type: "Withdraw",
          status: "pending",
          tx: tx1,
        });
        await tx1.wait();
        setTransactionData({
          type: "Withdraw",
          status: "success",
          tx: tx1,
        });
        updateDataPool();
        updateDataUser();
        setShowWithdrawInput(!showWithdrawInput);
      } catch (error) {
        console.log(error);
        setTransactionData({
          type: "Withdraw",
          status: "failed",
        });
        setShowWithdrawInput(!showWithdrawInput);
      }
    }
    if (pool === "dmodlp") {
      try {
        setIsOpen(true);
        setTransactionData({
          type: "Withdraw",
          status: "waiting for approval on metamask",
        });
        const tx1 = await staking.withdraw(
          addresses[network].dmodTokenLP,
          ethers.utils.parseEther(withdrawDmodAmount)
        );

        setTransactionData({
          type: "Withdraw",
          status: "pending",
          tx: tx1,
        });
        await tx1.wait();
        setTransactionData({
          type: "Withdraw",
          status: "success",
          tx: tx1,
        });
        updateDataPool();
        updateDataUser();
        setShowWithdrawInput(!showWithdrawInput);
      } catch (error) {
        console.log(error);
        setTransactionData({
          type: "Withdraw",
          status: "failed",
        });
        setShowWithdrawInput(!showWithdrawInput);
      }
    }
  };

  const harvest = async () => {
    if (totalAvailableReward <= 0) {
      return;
    }

    if (pool === "dmod") {
      const yieldFarmingDMOD = await getYieldFarmToken(signer.provider, signer);

      try {
        setIsOpen(true);
        setTransactionData({
          type: "Harvest Reward",
          status: "waiting for approval on metamask",
        });
        const tx1 = await yieldFarmingDMOD.massHarvest();

        setTransactionData({
          type: "Harvest Reward",
          status: "pending",
          tx: tx1,
        });
        await tx1.wait();
        setTransactionData({
          type: "Harvest Reward",
          status: "success",
          tx: tx1,
        });
        updateDataPool();
        updateDataUser();
      } catch (error) {
        console.log(error);
        setTransactionData({
          type: "Harvest Reward",
          status: "failed",
        });
      }
    }
    if (pool === "dmodlp") {
      const yieldFarmingLP = await getYieldFarmLP(signer.provider, signer);

      try {
        setIsOpen(true);
        setTransactionData({
          type: "Harvest Reward",
          status: "waiting for approval on metamask",
        });
        const tx1 = await yieldFarmingLP.massHarvest();

        setTransactionData({
          type: "Harvest Reward",
          status: "pending",
          tx: tx1,
        });
        await tx1.wait();
        setTransactionData({
          type: "Harvest Reward",
          status: "success",
          tx: tx1,
        });
        updateDataPool();
        updateDataUser();
      } catch (error) {
        console.log(error);
        setTransactionData({
          type: "Harvest Reward",
          status: "failed",
        });
      }
    }
  };

  const harvestAndStake = async () => {
    if (totalAvailableReward <= 0) {
      return;
    }

    if (pool === "dmod") {
      const yieldFarmingDMOD = await getYieldFarmToken(signer.provider, signer);

      try {
        setIsOpen(true);
        setTransactionData({
          type: "Harvest and Stake",
          status: "waiting for approval on metamask",
        });
        const tx1 = await yieldFarmingDMOD.harvestAndStake();

        setTransactionData({
          type: "Harvest and Stake",
          status: "pending",
          tx: tx1,
        });
        await tx1.wait();
        setTransactionData({
          type: "Harvest and Stake",
          status: "success",
          tx: tx1,
        });
        updateDataPool();
        updateDataUser();
      } catch (error) {
        console.log(error);
        setTransactionData({
          type: "Harvest and Stake",
          status: "failed",
        });
      }
    }
    if (pool === "dmodlp") {
      const yieldFarmingLP = await getYieldFarmLP(signer.provider, signer);

      try {
        setIsOpen(true);
        setTransactionData({
          type: "Harvest and Stake",
          status: "waiting for approval on metamask",
        });
        const tx1 = await yieldFarmingLP.harvestAndStake();

        setTransactionData({
          type: "Harvest and Stake",
          status: "pending",
          tx: tx1,
        });
        await tx1.wait();
        setTransactionData({
          type: "Harvest and Stake",
          status: "success",
          tx: tx1,
        });
        updateDataPool();
        updateDataUser();
      } catch (error) {
        console.log(error);
        setTransactionData({
          type: "Harvest and Stake",
          status: "failed",
        });
      }
    }
  };

  const setMaxAmount = async () => {
    let token;

    if (pool === "dmod") {
      token = await getToken(signer.provider, signer);
    }
    if (pool === "dmodlp") {
      token = await getLPToken(signer.provider, signer);
    }

    const balance = await token.balanceOf(address);

    setStakeDmodAmount(ethers.utils.formatEther(balance));
  };

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      isOpen={showModal}
      style={cardModalStyles}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => switchModal(false)}
      closeModal={() => switchModal(false)}
    >
      <MainCard>
        <ReactTooltip />
        <TopDiv>
          <CardTopRow>
            <Row
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {pool === "dmod" ? (
                <>
                  <LogoDiv>
                    <TableLogo src={Dmod} />
                  </LogoDiv>
                  <LogoDiv>
                    <TableLogo src={Dmod} />
                  </LogoDiv>
                </>
              ) : null}
              {pool === "dmodlp" ? (
                <>
                  <LogoDiv>
                    <TableLogo src={Dmod} />
                  </LogoDiv>
                  {network === "bnb" ? (
                    <LogoDiv>
                      <TableLogo src={Pancake} />
                    </LogoDiv>
                  ) : null}

                  {network === "homestead" ? (
                    <LogoDiv>
                      <TableLogo src={Uniswap} />
                    </LogoDiv>
                  ) : null}

                  {/* <LogoDiv>
                    <TableLogo src={Dmod} />
                  </LogoDiv>
                  <LogoDiv>
                    <TableLogo src={Uniswap} />
                  </LogoDiv> */}
                </>
              ) : null}
              <CardHeading>{name + " Pool"}</CardHeading>

              {poolApr !== "Infinity" && poolApr !== "NaN" && poolApr ? (
                <AprText>
                  APR {poolApr + "%"}
                  <img style={{ marginLeft: "0.4rem" }} src={Apr} alt="apr" />
                </AprText>
              ) : (
                <AprText>{"No Deposits"}</AprText>
              )}
            </Row>
            {pool === "dmod" ? (
              <>
                <a
                  href={
                    network === "homestead"
                      ? "https://app.uniswap.org/#/swap?use=V2"
                      : "https://exchange.pancakeswap.finance/#/swap"
                  }
                  target="#"
                >
                  {/* <CardSubTitle style={{ color: "#ff39f0", cursor: "pointer" }}>
                    {cardSubtitle}
                  </CardSubTitle> */}
                </a>
              </>
            ) : null}
            {pool === "dmodlp" ? (
              <>
                <a
                  href={
                    network === "homestead"
                      ? "https://app.uniswap.org/#/add/v2/ETH/0x5f6c5c2fb289db2228d159c69621215e354218d7"
                      : "https://exchange.pancakeswap.finance/#/add/BNB/0x002D8563759f5e1EAf8784181F3973288F6856e4"
                  }
                  target="#"
                >
                  {/* <CardSubTitle style={{ color: "#ff39f0", cursor: "pointer" }}>
                    {cardSubtitle}
                  </CardSubTitle> */}
                </a>
              </>
            ) : null}
          </CardTopRow>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "4rem",
            }}
          >
            <PoolInfoItems>
              <Bullet></Bullet>
              <Epoch style={{ float: "left", display: "flex" }}>
                Current Month
                <div
                  style={{ marginLeft: "5px", marginTop: "1px" }}
                  data-tip="The current month number of Liquidity Mining Program"
                >
                  <HelpCircle size="20" style={{ color: "#A6A0BB" }} />
                </div>
              </Epoch>
              <Epoch style={{ color: "#ffffff" }}>
                Month {currentEpochDMOD.toString()}
              </Epoch>
            </PoolInfoItems>
            <PoolInfoItems>
              <Epoch style={{ float: "left", display: "flex" }}>
                Current Month Reward{" "}
                <div
                  style={{ marginLeft: "5px", marginTop: "1px" }}
                  data-tip="Reward that will be distributed in the current month"
                >
                  <HelpCircle size="20" style={{ color: "#A6A0BB" }} />
                </div>
              </Epoch>
              <Epoch style={{ color: "#ffffff" }}>
                {numberWithCommas(
                  tokenBNtoNumber(rewardForCurrentEpoch).toFixed(2)
                )}{" "}
                DMOD
              </Epoch>
            </PoolInfoItems>
          </div>
        </TopDiv>
        <LastDiv>
          <PoolInfoItems style={{ display: "flex" }}>
            <BottomText style={{ display: "flex", flexGrow: 1 }}>
              Total {name} Locked{" "}
              <div
                style={{ marginLeft: "5px", marginTop: "1px" }}
                data-tip="Total number of tokens locked in this pool"
              >
                <HelpCircle size="20" style={{ color: "#A6A0BB" }} />
              </div>
            </BottomText>
            <BottomNumber style={{ color: "#ffffff", fontWeight: 500 }}>
              {numberWithCommas(tokenBNtoNumber(poolBalance).toFixed(2))} {name}
            </BottomNumber>
          </PoolInfoItems>
          <PoolInfoItems style={{ display: "flex" }}>
            <BottomText style={{ display: "flex", flexGrow: 1 }}>
              Pool Active Time Period{" "}
              <div
                style={{ marginLeft: "5px", marginTop: "1px" }}
                data-tip="Number of months left while this pool will be functioning"
              >
                <HelpCircle size="20" style={{ color: "#A6A0BB" }} />
              </div>
            </BottomText>
            <BottomNumber style={{ color: "#ffffff", fontWeight: 500 }}>
              {totalEpochDMOD.sub(currentEpochDMOD).toString()} Months Left
            </BottomNumber>
          </PoolInfoItems>
          <PoolInfoItems style={{ display: "flex" }}>
            <BottomText style={{ display: "flex", flexGrow: 1 }}>
              Total DMOD Allocated{" "}
              <div
                style={{ marginLeft: "5px", marginTop: "1px" }}
                data-tip="Total DMOD tokens allocated to this pool for rewards"
              >
                <HelpCircle size="20" style={{ color: "#A6A0BB" }} />
              </div>
            </BottomText>
            <BottomNumber style={{ color: "#ffffff", fontWeight: 500 }}>
              {numberWithCommas(
                tokenBNtoNumber(totalDistributedAmount).toFixed(0)
              )}{" "}
              DMOD
            </BottomNumber>
          </PoolInfoItems>
        </LastDiv>
        {signer ? (
          <>
            {network === "bnb" || network === "homestead" ? (
              <>
                <>
                  {tokenBNtoNumber(epochStakeNext) > 0 ? (
                    <LastDivTwo>
                      <ReactTooltip />
                      <div style={{ display: "flex" }}>
                        <StakeText
                          style={{ display: "flex", flexGrow: 1, margin: 0 }}
                        >
                          Staked {name}{" "}
                          <div
                            style={{ marginLeft: "5px", marginTop: "1px" }}
                            data-tip="Your stake in the Pool"
                          >
                            <HelpCircle
                              size="20"
                              style={{ color: "#A6A0BB" }}
                            />
                          </div>
                        </StakeText>
                        <EarnedNumber>
                          {numberWithCommas(
                            tokenBNtoNumber(epochStakeNext).toFixed(2)
                          )}{" "}
                          {name}
                        </EarnedNumber>
                      </div>
                      <div>
                        <div style={{ display: "flex" }}>
                          <EarnedText style={{ display: "flex", flexGrow: 1 }}>
                            DMOD Earned Till Now{" "}
                            <div
                              style={{ marginLeft: "5px", marginTop: "1px" }}
                              data-tip="The amount of DMOD you have earned till now through this program. This also includes the tokens you have already harvested"
                            >
                              <HelpCircle
                                size="20"
                                style={{ color: "#A6A0BB" }}
                              />
                            </div>
                          </EarnedText>
                          <EarnedNumber>
                            {numberWithCommas(
                              totalAccumulatedReward.toFixed(2)
                            )}{" "}
                            DMOD
                          </EarnedNumber>
                        </div>
                      </div>
                      <div>
                        <div style={{ display: "flex" }}>
                          <EarnedText style={{ display: "flex", flexGrow: 1 }}>
                            Total Available Reward
                            <div
                              style={{ marginLeft: "5px", marginTop: "1px" }}
                              data-tip="The reward amount you can transfer to your account right now, by clicking on harvest button"
                            >
                              <HelpCircle
                                size="20"
                                style={{ color: "#A6A0BB" }}
                              />
                            </div>
                          </EarnedText>
                          <EarnedNumber>
                            {numberWithCommas(totalAvailableReward.toFixed(2))}{" "}
                            DMOD
                          </EarnedNumber>
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <EarnedTextTwo style={{ display: "flex", flexGrow: 1 }}>
                          Estimated Reward for Current Month{" "}
                          <div
                            style={{ marginLeft: "5px", marginTop: "1px" }}
                            data-tip="This is an approximation of the reward you might get for this month. This is not the final amount and may changed based on other participants and deposits in the pool"
                          >
                            <HelpCircle
                              size="20"
                              style={{ color: "#A6A0BB" }}
                            />
                          </div>
                        </EarnedTextTwo>
                        <EarnedNumberTwo>
                          {numberWithCommas(potentialUserReward.toFixed(2))}{" "}
                          DMOD{" "}
                          <img
                            style={{ width: "1rem" }}
                            src={Star}
                            alt="star"
                          />
                        </EarnedNumberTwo>
                      </div>
                    </LastDivTwo>
                  ) : null}
                </>
                {totalAvailableReward > 0 ? (
                  <div
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                      marginTop: "1rem",
                    }}
                  >
                    <HarvestButton onClick={harvest}>
                      <ButtonText>Harvest Reward</ButtonText>

                      {/* {totalAvailableReward.toFixed(2) > 0
                        ? totalAvailableReward.toFixed(2)
                        : "123123.1213"} */}
                    </HarvestButton>
                    <HarvestButton
                      style={{ float: "right", marginLeft: "5px" }}
                      onClick={harvestAndStake}
                    >
                      <ButtonText>Harvest And Stake</ButtonText>
                      {/* {totalAvailableReward.toFixed(2) > 0
                        ? totalAvailableReward.toFixed(2)
                        : null} */}
                    </HarvestButton>
                  </div>
                ) : null}
                <>
                  <>
                    {showStakeInput ? (
                      <>
                        <TokenInput>
                          <Input
                            onChange={(e) => {
                              setStakeDmodAmount(e.target.value);
                            }}
                            value={stakeDmodAmount}
                            placeholder="Enter Value"
                          />
                          <MaxNumber onClick={setMaxAmount}>Max</MaxNumber>
                        </TokenInput>
                      </>
                    ) : null}
                    {showWithdrawInput ? (
                      <TokenInput>
                        <Input
                          onChange={(e) => {
                            setWithdrawDmodAmount(e.target.value);
                          }}
                          placeholder="Enter Value"
                        />
                      </TokenInput>
                    ) : null}
                  </>
                  <PoolActionContainer>
                    {!showStakeInput && !showWithdrawInput ? (
                      <>
                        <StakeButton
                          withdraw
                          onClick={() => {
                            setShowWithdrawInput(!showWithdrawInput);
                          }}
                        >
                          Withdraw
                        </StakeButton>
                        <StakeButton
                          onClick={() => {
                            setShowStakeInput(!showStakeInput);
                          }}
                        >
                          Stake
                        </StakeButton>
                      </>
                    ) : (
                      <>
                        {showStakeInput ? (
                          <>
                            <StakeButton
                              withdraw
                              onClick={() => {
                                setShowStakeInput(!showStakeInput);
                              }}
                            >
                              Cancel
                            </StakeButton>
                            <StakeButton onClick={depositDmod}>
                              Deposit
                            </StakeButton>
                          </>
                        ) : null}
                        {showWithdrawInput ? (
                          <>
                            <StakeButton
                              withdraw
                              onClick={() => {
                                setShowWithdrawInput(!showWithdrawInput);
                              }}
                            >
                              Cancel
                            </StakeButton>
                            <StakeButton onClick={withdrawDmod}>
                              Withdraw
                            </StakeButton>
                          </>
                        ) : null}
                      </>
                    )}
                  </PoolActionContainer>
                </>
              </>
            ) : (
              <h4
                style={{
                  lineHeight: "24px",
                  fontSize: "1.1rem",
                  fontWeight: 600,
                }}
              >
                Staking is currently only available on Mainnet and Binance Smart
                Chain
              </h4>
            )}
          </>
        ) : (
          <MetamaskButton onClick={loadWeb3Modal}>
            Connect Metamask
          </MetamaskButton>
        )}
      </MainCard>
      <TransactionModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        customStyles={customStyles}
        transactionData={transactionData}
        network={network}
      />
    </Modal>
  );
}
export default PoolCard;
