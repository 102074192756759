import React, { useEffect, useState } from "react";
import Cards from "./components/Cards";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Section from "./components/Section";
import MainTable from "./components/Table";
import useWeb3Modal from "./hooks/useWeb3Modal";
import LeftCoin from "./images/coin_left.png";
import RightCoin from "./images/coin_right.png";
import { TopSection } from "./styles";
import "./styles/index.css";

function App() {
  const [showModalDMOD, setShowModalDMOD] = useState(false);
  const [showModalPoolLp, setShowModalPoolLp] = useState(false);

  const [
    provider,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    signer,
    address,
    network,
    dmodBalance,
  ] = useWeb3Modal();

  const switchDMODModal = (state) => {
    setShowModalDMOD(state);
  };

  const switchLPModal = (state) => {
    setShowModalPoolLp(state);
  };

  useEffect(() => {
    loadWeb3Modal();
  }, [loadWeb3Modal]);

  return (
    <div>
      <>
        <div class="shooting_star_container">
          <div class="shooting_star"></div>
        </div>
        <div className="blue-opacity"></div>
        <div className="stars"></div>
        <div className="bg-planet"></div>
        <img class="coin left" src={LeftCoin} alt="leftcoin" />
        <img class="coin right" src={RightCoin} alt="rightcoin" />

        <div class="planet left">
          <div class="wrap">
            <div class="background"></div>
            <div class="clouds"></div>
          </div>
          <div class="mask"></div>
        </div>

        <div class="planet right">
          <div class="wrap">
            <div class="background"></div>
            <div class="clouds"></div>
          </div>
          <div class="mask"></div>
        </div>
        <TopSection>
          <Header
            web3={[
              provider,
              loadWeb3Modal,
              logoutOfWeb3Modal,
              signer,
              address,
              network,
              dmodBalance,
            ]}
          />
          <Section
            web3={[
              provider,
              loadWeb3Modal,
              logoutOfWeb3Modal,
              signer,
              address,
              network,
            ]}
          />
        </TopSection>
        {/* <TableCard
          web3={[
            provider,
            loadWeb3Modal,
            logoutOfWeb3Modal,
            signer,
            address,
            network,
            dmodBalance,
          ]}
          switchDMODModal={switchDMODModal}
          switchLPModal={switchLPModal}
        /> */}
        <MainTable
          web3={[
            provider,
            loadWeb3Modal,
            logoutOfWeb3Modal,
            signer,
            address,
            network,
          ]}
          switchDMODModal={switchDMODModal}
          switchLPModal={switchLPModal}
        />

        <Cards
          web3={[
            provider,
            loadWeb3Modal,
            logoutOfWeb3Modal,
            signer,
            address,
            network,
          ]}
          switchDMODModal={switchDMODModal}
          switchLPModal={switchLPModal}
          showModalDMOD={showModalDMOD}
          showModalPoolLp={showModalPoolLp}
        />
        <FAQ />
        <Footer />
      </>
    </div>
  );
}

export default App;
