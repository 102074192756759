import {
  faChevronDown,
  faCircle,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import {
  BookOpen,
  Box,
  Code,
  Droplet,
  Info,
  Link,
  MessageCircle,
  MessageSquare,
} from "react-feather";
import useScreenType from "react-screentype-hook";
import ReactTooltip from "react-tooltip";
import Web3 from "web3";
import usePrice from "../hooks/usePrice";
import Bsc from "../images/bridge.svg";
import dmod from "../images/dmod2.svg";
import logo from "../images/dmodLogo.png";
import Eth from "../images/ethereum.svg";
import external from "../images/external.svg";
import {
  Button,
  ConnectButtonText,
  DmodContainer,
  DmodImage,
  DmodImgTablet,
  ExternalImg,
  Imagediv as ImageDiv,
  Logo,
  MainHeader,
  MenuBar,
  MenuFlyout,
  MenuFlyoutTablet,
  MenuItemLink,
  NavIconButton,
  NavSpan,
  NavText,
  NetworkButton,
  NetworkContainer,
  NetworkIcon,
  NetworkItem,
  NetworkTitle,
  TabletConnectButtonText,
  TabletMenuDiv,
  WalletAddressContainer,
  WalletDiv,
  WalletSection,
} from "../styles";
import { formatAddress } from "../utils";
import Identicon from "./Identicon";
import AccountInfoModal from "./Modals/AccountInfoModal";
import NetworkDropdown from "./NetworkDropdown";

function WalletButton({
  provider,
  loadWeb3Modal,
  logoutOfWeb3Modal,
  signer,
  address,
}) {
  return (
    <>
      {!address ? (
        <Button
          onClick={() => {
            if (!address) {
              loadWeb3Modal();
            } else {
              logoutOfWeb3Modal();
            }
          }}
        >
          Connect
        </Button>
      ) : null}
    </>
  );
}
function Header({ web3 }) {
  const [
    provider,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    signer,
    address,
    network,
    dmodBalance,
  ] = web3;

  const [showNetworkDropdown, setShowNetworkDropdown] = React.useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [ethBalance, setEthBalance] = React.useState(0);

  const dmodPrice = usePrice("demodyfi");

  const getEthBalance = useCallback(async () => {
    if (address) {
      try {
        const web3 = new Web3(provider.provider);
        const balance = await web3.eth.getBalance(address);
        setEthBalance(parseFloat(web3.utils.fromWei(balance)).toFixed(2));
      } catch (error) {
        console.log(
          "%cMyProject%cline:119%cerror",
          "color:#fff;background:#ee6f57;padding:3px;border-radius:2px",
          "color:#fff;background:#1f3c88;padding:3px;border-radius:2px",
          "color:#fff;background:rgb(3, 101, 100);padding:3px;border-radius:2px",
          error
        );
      }
    }
  }, [address, provider]);

  useEffect(() => {
    getEthBalance();
  }, [getEthBalance]);

  const switchNetwork = async (type) => {
    if (type === "bsc") {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x38",
            chainName: "Binance Smart Chain",
            nativeCurrency: {
              name: "BNB",
              symbol: "BNB",
              decimals: 18,
            },
            rpcUrls: ["https://bsc-dataseed.binance.org"],
            blockExplorerUrls: ["https://bscscan.com/"],
          },
        ],
      });
    } else if (type === "eth") {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x1" }],
      });
    }
  };

  const addDMODToMetamask = async () => {
    if (!network) {
      return;
    }

    if (network === "homestead") {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: "0x5f6c5c2fb289db2228d159c69621215e354218d7", // The address that the token is at.
            symbol: "DMOD", // A ticker symbol or shorthand, up to 5 chars.
            decimals: 18, // The number of decimals in the token
            image:
              "https://s2.coinmarketcap.com/static/img/coins/64x64/9713.png", // A string url of the token logo
          },
        },
      });
    } else {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: "0x002D8563759f5e1EAf8784181F3973288F6856e4", // The address that the token is at.
            symbol: "DMOD", // A ticker symbol or shorthand, up to 5 chars.
            decimals: 18, // The number of decimals in the token
            image:
              "https://s2.coinmarketcap.com/static/img/coins/64x64/9713.png", // A string url of the token logo
          },
        },
      });
    }
  };

  const screenType = useScreenType();
  const [isOn, toggleIsOn] = useToggle();
  function useToggle(initialValue = false) {
    const [value, setValue] = React.useState(initialValue);
    const toggle = React.useCallback(() => {
      setValue((v) => !v);
    }, []);
    return [value, toggle];
  }
  return (
    <>
      <MainHeader>
        <MenuBar>
          <ImageDiv>
            <Logo src={logo} />
          </ImageDiv>
          <NavSpan>
            <a
              style={{ textDecoration: "none" }}
              href="https://app.demodyfi.com"
              target="#"
            >
              <NavText>
                Dex <ExternalImg src={external} />
              </NavText>{" "}
            </a>
            <a
              style={{ textDecoration: "none" }}
              href="https://app.demodyfi.com/#/earn"
              target="#"
            >
              <NavText>
                Pools <ExternalImg src={external} />
              </NavText>{" "}
            </a>
            <a
              style={{ textDecoration: "none" }}
              href="https://bridge.demodyfi.com"
              target="#"
            >
              <NavText>
                Bridge <ExternalImg src={external} />
              </NavText>{" "}
            </a>
          </NavSpan>
          {signer ? (
            <NetworkContainer>
              <NetworkButton onClick={(e) => setShowNetworkDropdown(true)}>
                {network === "bnb" ? (
                  <NetworkItem>
                    <NetworkIcon src={Bsc}></NetworkIcon>
                    <NetworkTitle>BSC</NetworkTitle>
                  </NetworkItem>
                ) : null}
                {network === "homestead" ? (
                  <NetworkItem>
                    <NetworkIcon src={Eth}></NetworkIcon>
                    <NetworkTitle>Ethereum</NetworkTitle>
                  </NetworkItem>
                ) : null}
                {network !== "bnb" && network !== "homestead" ? (
                  <NetworkItem>
                    <NetworkIcon
                      src={
                        "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Question_mark_white_icon.svg/1200px-Question_mark_white_icon.svg.png"
                      }
                    ></NetworkIcon>
                    <NetworkTitle>Invalid Network!</NetworkTitle>
                  </NetworkItem>
                ) : null}
                <FontAwesomeIcon icon={faChevronDown} color="white" />
              </NetworkButton>
              {showNetworkDropdown && (
                <NetworkDropdown
                  setShowDropdown={setShowNetworkDropdown}
                  switchNetwork={switchNetwork}
                />
              )}
            </NetworkContainer>
          ) : null}
          {address && (
            <>
              <ReactTooltip />
              <DmodContainer
                data-tip="Add DMOD to Metamask"
                onClick={addDMODToMetamask}
              >
                <DmodImage src={dmod} />
                <div
                  style={{
                    float: "right",
                  }}
                >
                  <ConnectButtonText>
                    {" "}
                    $ {dmodPrice.toFixed(3)}
                  </ConnectButtonText>
                </div>
              </DmodContainer>
              <WalletSection>
                <WalletDiv>
                  <FontAwesomeIcon
                    color="#53F3C3"
                    style={{ width: "0.5rem", marginRight: "0.5rem" }}
                    icon={faCircle}
                  />
                  {ethBalance} {network === "homestead" ? "ETH" : "BNB"}
                  {/* {getCurrentChain(provider.chainId)?.symbol ?? "NaN"} */}
                </WalletDiv>
                <WalletAddressContainer
                  onClick={() => setShowAccountModal(true)}
                >
                  <ConnectButtonText style={{ display: "flex" }}>
                    {" "}
                    {formatAddress(address)}
                    <Identicon address={address} />
                  </ConnectButtonText>
                </WalletAddressContainer>
              </WalletSection>
            </>
          )}

          <WalletButton
            provider={provider}
            loadWeb3Modal={loadWeb3Modal}
            logoutOfWeb3Modal={logoutOfWeb3Modal}
            signer={signer}
            address={address}
          />
          <NavIconButton onClick={toggleIsOn}>
            {" "}
            <FontAwesomeIcon color="white" icon={faEllipsisH} />
          </NavIconButton>
          {isOn ? (
            <div>
              <MenuFlyout>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href="https://demodyfi.com"
                >
                  <Info size={14} />
                  About
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href="https://docs.demodyfi.com"
                >
                  <BookOpen size={14} />
                  Docs
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href={"https://github.com/Demodyfi"}
                >
                  <Code size={14} />
                  Code
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href="https://discord.gg/zWDyzKQFH9"
                >
                  <MessageCircle size={14} />
                  Discord
                </MenuItemLink>
              </MenuFlyout>
              <MenuFlyoutTablet>
                {address && (
                  <div>
                    <TabletMenuDiv style={{ display: "flex" }}>
                      <FontAwesomeIcon
                        color="#53F3C3"
                        style={{
                          width: "0.5rem",
                          marginRight: "0.7rem",
                          marginLeft: "0.5rem",
                          marginTop: "0.2rem",
                        }}
                        icon={faCircle}
                      />
                      {ethBalance} {network === "homestead" ? "ETH" : "BNB"}
                    </TabletMenuDiv>
                    {/* <Divider style={{ background: "#1f1f1f", height: "0.1px" }} /> */}
                  </div>
                )}
                <TabletMenuDiv
                  style={{ display: "flex", marginLeft: "0.3rem" }}
                >
                  <DmodImgTablet src={dmod} />
                  <TabletConnectButtonText
                    style={{ marginBottom: "10px", marginTop: "0.5rem" }}
                  >
                    $ {dmodPrice.toFixed(3)}
                  </TabletConnectButtonText>
                </TabletMenuDiv>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href="https://demodyfi.com"
                >
                  <Info size={14} />
                  About
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href="https://docs.demodyfi.com"
                >
                  <BookOpen size={14} />
                  Docs
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href={"https://github.com/Demodyfi"}
                >
                  <Code size={14} />
                  Code
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href="https://discord.gg/zWDyzKQFH9"
                >
                  <MessageCircle size={14} />
                  Discord
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href="mailto:dev@demodyfi.com"
                >
                  <MessageSquare size={14} />
                  Contact Us
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href="https://app.demodyfi.com"
                >
                  <Box size={14} />
                  Dex <ExternalImg src={external} />
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href="https://app.demodyfi.com/#/earn"
                >
                  <Droplet size={14} />
                  Pools <ExternalImg src={external} />
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href="https://bridge.demodyfi.com"
                >
                  <Link size={14} />
                  Bridge <ExternalImg src={external} />
                </MenuItemLink>
              </MenuFlyoutTablet>
            </div>
          ) : null}
        </MenuBar>

        {/* <MenuBar>
          <ImageDiv>
            <Logo src={logo} />
          </ImageDiv>
          {signer ? (
            <NetworkContainer>
              <NetworkButton onClick={(e) => setShowNetworkDropdown(true)}>
                {network === "bnb" ? (
                  <NetworkItem>
                    <NetworkIcon
                      src={
                        "https://ik.imagekit.io/argo/bsc-network_2s0gTN2Af.jpg"
                      }
                    ></NetworkIcon>
                    <NetworkTitle></NetworkTitle>
                  </NetworkItem>
                ) : null}
                {network === "homestead" ? (
                  <NetworkItem>
                    <NetworkIcon
                      src={
                        "https://ik.imagekit.io/argo/ethereum_tj3NR3wTN7.jpeg"
                      }
                    ></NetworkIcon>
                    <NetworkTitle></NetworkTitle>
                  </NetworkItem>
                ) : null}
                {network !== "bnb" && network !== "homestead" ? (
                  <NetworkItem>
                    <NetworkIcon
                      src={
                        "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Question_mark_white_icon.svg/1200px-Question_mark_white_icon.svg.png"
                      }
                    ></NetworkIcon>
                    <NetworkTitle>Invalid Network!</NetworkTitle>
                  </NetworkItem>
                ) : null}
                <FontAwesomeIcon icon={faChevronDown} color="white" />
              </NetworkButton>
              {showNetworkDropdown && (
                <NetworkDropdown
                  setShowDropdown={setShowNetworkDropdown}
                  switchNetwork={switchNetwork}
                />
              )}
              {address && (
                <WalletAddressContainer>
                  {address.substr(0, 4) + "..." + address.substr(-4)}
                </WalletAddressContainer>
              )}
            </NetworkContainer>
          ) : null}

          <WalletButton
            provider={provider}
            loadWeb3Modal={loadWeb3Modal}
            logoutOfWeb3Modal={logoutOfWeb3Modal}
            signer={signer}
            address={address}
          />
          <NavIconButton onClick={toggleIsOn}>
            {" "}
            <FontAwesomeIcon color="white" icon={faEllipsisH} />
          </NavIconButton>
          {isOn ? (
            <div>
              <MenuFlyout>
                <MenuItemLink id="link" onClick={() => history.push("/")}>
                  <Info size={14} />
                  Home
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href="https://demodyfi.com"
                >
                  <Info size={14} />
                  About
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href="https://docs.demodyfi.com"
                >
                  <BookOpen size={14} />
                  Docs
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href={"https://github.com/Demodyfi"}
                >
                  <Code size={14} />
                  Code
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href="https://discord.gg/zWDyzKQFH9"
                >
                  <MessageCircle size={14} />
                  Discord
                </MenuItemLink>
              </MenuFlyout>
              <MenuFlyoutTablet>
                {provider ? (
                  <div>
                    <TabletMenuDiv style={{ display: "flex" }}>
                      <FontAwesomeIcon
                        color="#53F3C3"
                        style={{
                          width: "0.5rem",
                          marginRight: "0.7rem",
                          marginLeft: "0.5rem",
                          marginTop: "0.2rem",
                        }}
                        icon={faCircle}
                      />
                      {ethBalance}{" "}
                      {getCurrentChain(provider.chainId)?.symbol ?? "NaN"}
                    </TabletMenuDiv>
                    <TabletMenuDiv
                      style={{ display: "flex", marginLeft: "0.3rem" }}
                    >
                      <DmodImgTablet src={dmod} />
                      <TabletConnectButtonText
                        style={{ marginBottom: "10px", marginTop: "0.5rem" }}
                      >
                        $ {dmodBalance}
                      </TabletConnectButtonText>
                    </TabletMenuDiv>
                    <Divider
                      style={{ background: "#1f1f1f", height: "0.1px" }}
                    />
                  </div>
                ) : null}
                <MenuItemLink id="link" onClick={() => history.push("/")}>
                  <HelpCircle size={14} />
                  Home
                </MenuItemLink>

                <MenuItemLink
                  id="link"
                  target="_blank"
                  href="https://demodyfi.com"
                >
                  <Info size={14} />
                  About
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href="https://docs.demodyfi.com"
                >
                  <BookOpen size={14} />
                  Docs
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href={"https://github.com/Demodyfi"}
                >
                  <Code size={14} />
                  Code
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href="https://discord.gg/zWDyzKQFH9"
                >
                  <MessageCircle size={14} />
                  Discord
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href="mailto:dev@demodyfi.com"
                >
                  <MessageSquare size={14} />
                  Contact Us
                </MenuItemLink>
                <MenuItemLink
                  id="link"
                  target="_blank"
                  href="https://app.demodyfi.com"
                >
                  <Box size={14} />
                  Dex <sup>↗</sup>
                </MenuItemLink>
              </MenuFlyoutTablet>
            </div>
          ) : null}
        </MenuBar> */}
      </MainHeader>
      <AccountInfoModal
        web3={web3}
        modalIsOpen={showAccountModal}
        closeModal={() => setShowAccountModal(false)}
      />
    </>
  );
}

export default Header;
